let all = new Map,
	request = indexedDB.open( "users", 3 ),
	db,
	loadset = new Set, loadresolve = new Map,
	waitdb = new Set;

function dbReady() {
	if( db ) return;
	return new Promise( resolve => {
		waitdb.add( resolve );
	} );
}

if( request ) {
	request.onsuccess = () => {
		window.log?.( "IndexedDB users opened" );
		db = request.result;
		for( let f of waitdb ) f();
		waitdb.clear();
	};

	request.onupgradeneeded = event => {
		let d = event.target.result;

		d.onerror = () => {
			window.log && log( "Error creating users" );
		};
		window.log && log( "Creating store users" );
		if( event.oldVersion>0 ) {
			try {
				d.deleteObjectStore( "users" );
			} catch( e ) {
			}
		}
		try {
			let users = d.createObjectStore( "users" );
			users.createIndex( 'id', 'id', { unique: true } );
		} catch( err ) {
			window.log && log( 'Error while upgrading users db ' + JSON.stringify( err ) );
		}
	};
}

let fetchSet = new Set;

export default class User {
	static #nostoreflag;
	static #storedFields =
		[ 'members', 'strid', 'picture', 'avt_postfix', 'name', 'fio', 'officialfoto', 'currsymbol', 'official', 'admins',
		  'entrance_balance', 'ttl', 'ttl_hours', 'country' ];

	#checkFields; #deleted;

	constructor( id, name, picture, nopicture ) {
		if( typeof id==='number' )
			log( 'newUSER (number) ' + id );
		if( id.match( /^t\d+$/ ) ) id = 'team_' + id.slice( 1 );
		if( id.startsWith( 'user_' ) ) id = id.replace( 'user_', '' );
		this.id = id;
		this.picture = picture;
		if( this.id==='t0' ) {
			if( LOCALTEST ) debugger;
			return;
		}
		all.set( this.id, this );
		if( this.objectid==='team' ) all.set( 't' + this.numericid, this );
		if( name )
			this.name = name;

		if( !name || ( !nopicture && !this.picture ) )
			this.load();
	}

	cancelLoading() {
		// Отменим процедуру загрузки из локального хранилища
		delete this.loading;
	}

	async load() {
		if( this.#deleted ) return;
		if( this.loading==='LOADING' ) return;
		this.loading = 'LOADING';
		await dbReady();
		let r;
		try {
			// log( `User ${this.itemid} try load` );
			r = db.transaction( ["users"] ).objectStore( 'users' ).get( this.id );
		} catch( e ) {
			log( 'DB ' + JSON5.stringify( e ) );
			return;
		}
		if( !r ) {
			log( 'User loading failed db transaction' );
			return this.fetch();
		}
		// if( window.log ) log( 'Try loadObject ' + this.id + '...' );
		r.onerror = event => {
			this.loading = 'FAILED';
			log( `Loading ${this.itemid} error` );
		};
		r.onsuccess = event => {
			if( this.loading!=='LOADING' ) {
				// Loading canceled
				log( `User ${this.itemid} loading canceled` );
				return;
			}
			// delete this.loading;
			let res = event.target.result;
			if( !res ) {
				if( window.log ) log( `Loading ${this.id} success failed` );
				this.loading = 'FAILED';
				this.fetch();
				return;
			}
			if( /[\?_]/.test( res.picture ) || res.picture==='undefined' ) res.picture = null;
			if( window.log ) log( `Loading ${this.id} ok: ${JSON.stringify( res )}` );
			if( res.avt_postfix && ( FANTGAMES || NEOBRIDGE ) ) delete res.avt_postfix;
			User.#nostoreflag = true;
			this.loading = 'DONE';
			this.lastfetchtime = res.lastfetchtime;
			this.setShowName( res.name || res.showName );
			if( this.#checkFields ) {
				// Проверим не загружаем ли мы старые значения из локальной базы
				for( let f of this.#checkFields ) {
					if( !(f in res) || this[f]!==res[f] ) {
						this.store( 'New fields differs from stored' );
						delete res[f];
					}
				}
				this.#checkFields = null;
			}
			if( res.picture || res.avatarid || res.avt_postfix ) this.setPicture( res.picture || res.avatarid, res.avt_postfix );
			else if( res.picture ) this.setPicture( res.picture );
			else if( !this.picture ) this.setPicture();
			this.setOfficial( res.fio );
			for( let o of User.#storedFields )
				if( o in res ) this[o] = res[o];
			User.#nostoreflag = false;
			loaded( this );

			let n = res.name || res.showName;
			if( !n || n===res.id || n[0]==='?' || (!res.avatarid && !res.avt_postfix && !res.picture) || (Date.now()-(res.lastfetchtime||0)>1000*60*60*24*14) ) {
				if( Date.now()-(res.lastfetchtime||0) > 60000 ) {
					// Чаще чем раз в минуту запрос о пользователе не делаем
					if( window.log ) log( 'dataFetch ' + this.id );
					this.fetch();
				}
			} else {
				// document.dispatchEvent( new CustomEvent( 'userupdate', { detail: this } ) );
			}
		}
	}

	isrobot() {
		return this.name==='Robo' || this.name==='R' || this.fio==='r' || this.name?.startsWith( '{Robot}' );
	}

	get getShowNameEnc() {
		return fixedEncodeURIComponent( this.getShowName );
	}

	get getShowNameWithFlag() {
		let name = this.getShowName;
		if( this.country ) {
			let flag = this.country.toUpperCase()
				.split('')
				.map(char => String.fromCodePoint(char.charCodeAt(0) + 0x1F1E6))
				.join('');
			name = flag + name;
		}
		return name;
	}

	get getShowName() {
		let name = this.realname || this.name;
		if( name?.[0]==='@' && UIN===this.id ) return localize( "{Me}" );
		if( name ) return this.id===UIN? name : needTransliterate( name );
		if( this.id===window.UIN ) {
			// if( core.auth.info.showname ) return core.auth.info.showname;
			return localize( "{Me}" );
		}
		return '';
	}

	setGlobal( field, value ) {
		value ??= this[field];
		setGlobalData( `${this.itemid}.${field}`, value )
	}

	setShowName( str ) {
		if( !str || this.name===str ) return;
		this.name = str;
		this.store( 'setShowName' );
		if( this.id.startsWith( 'tour_' ) && str.includes( '{' ) ) this.realname = localize( str, { notranslit: true } );
		this.setGlobal( `showname`, this.realname || str );
		// if( LOCALTEST )
		// 	setGlobalData( `${this.itemid}.name`, this.realname || str );
		return true;
	}

	storeNewValue( field, reason ) {
		if( this.fetching ) return;	// API всегда отдает самые свежие значения
		if( !User.#storedFields.includes( field ) ) return;		// Это поле не сохраняется
		if( this.loading!=='LOADING' ) return this.store( reason );
		// Идет загрузка из локальной базы, а там может быть устаревшее значение. Оно не должно затереть новое.
		// при этом, если в базе уже новое значение, то сохранять не нужно
		this.#checkFields ||= new Set;
		this.#checkFields.add( field );
	}

	exportValue( fieldname ) {
		if( fieldname==='freetrial' ) return +this.isFreetrial;
		return this[fieldname];
	}

	setFieldValue( field, value, storereason ) {
		if( !value || this[field]===value ) return;
		this[field] = value;
		setGlobalData( `${this.itemid}.${field}`, value );
		// updateOrigin( this.itemid, { [field]: this.exportValue( field ) } );
		if( !this.#delayUpdate ) {
			this.#delayUpdate = {};
			User.#delaySet.add( this );
			User.#delayID ||= requestAnimationFrame( User.updateDOM );
			// User.needUpdateDOM( this );
		}
		this.#delayUpdate[field] = this.exportValue( field );
		// if( LOCALTEST )		// Testing double
		// 	setGlobalData( `${this.itemid}.${field}`, value );
		// Если в этот момент идет загрузка из локальной базы, и в ней оказывается уже новое значение,
		// то сохранять не надо
		// если же в локальной базе значение старое, то надо его игнорировать, а затем пересохранить
		if( storereason ) this.storeNewValue( field, storereason );
		return true;
	}

	setRegion( str ) {
		if( !str ) return;
		str = str.toLowerCase();
		this.regionEmoji =
			String.fromCodePoint( 127462 + str.charCodeAt( 0 ) - 'a'.charCodeAt( 0 ) ) +
			String.fromCodePoint( 127462 + str.charCodeAt( 1 ) - 'a'.charCodeAt( 0 ) );
	}

	setOfficial( str ) {
		if( !str || this.fio===str ) return;
		this.fio = str;
		if( str && str!=='robot' ) {
			let p = str.split( ' ' );
			if( p.length>1 ) this.off = p[0][0].toUpperCase() + '. ' + p[1].capitalize();
			this.setGlobal( `official`, this.off || str );
			// if( window.log ) log( 'DEBUG TEST getnick(' + JSON.stringify( this.id ) + ') is ' + User.getNick( this.id ) );
		}
	}

/*
	get getfio() {
		return needTransliterate( this.fio );
	}
*/

	get officialName() {
		// Транслит, если необходимо
		return this.isMe? this.off : needTransliterate( this.off );
	}

	setBalance( bal ) {
		let update = this.mybalance!==undefined;
		this.setMe( 'balance', bal );
		if( update )
			setGlobalData( `${this.itemid}.balance`, bal )
			// this.setGlobal( `mybalance`, showBalance( this.balance, this.currency ) );
	}

	setMe( param, value ) {
		if( !UIN ) return;
		if( !this.myData || this.myData.UIN!==UIN ) this.myData = { UIN: UIN };
		this.myData[param] = value;
	}

	my( param ) {
		if( this.myData?.UIN!==UIN ) return undefined;
		return this.myData?.[param];
	}

	static clearMe() {
		User.myData = null;
	}

	get captain() {
		if( this.cap ) return this.cap;
		if( this.capuin ) return ( this.cap = User.set( this.capuin ) );
		if( this.admins?.length ) this.cap = User.set( this.admins[0] );
		return this.cap;
	}

	// Specific functions about me and Team
/*
	get mybalance() {
		return (this.balance || this.currsymbol)? (this.balance||'0') + ' ' + (this.currency||'') : null;
	}
*/

	get isDemoclub() { return this.strid==='demo' || this.id==='team_1'; }

	get isMember() {
		if( this.strid==='demo' || ( FANTGAMES && this.id==='team_1' ) ) return true;
		if( this.isHelper ) return true;
		return elephCore?.getMyTeam( this.numericid ) || false;
	}

	get mystate() { return this.my( 'state' ); }
	get mybalance() {
		let b = this.my( 'balance' );
		if( b ) return b;
		if( this.entrance_balance ) {
			if( this.isDemoclub || this.isMember ) return this.entrance_balance;
		}
	}

	get isOwner() {
		return UIN && this.owner===UIN;
	}

	get isCaptain() {
		if( this.strid==='demo' || ( FANTGAMES && this.id==='team_1' ) ) return true;
		return this.captain?.isMe || this.mystate?.includes( 'D' ) || false;
	}

	get isHelper() {
		if( this.strid==='demo' || ( FANTGAMES && this.id==='team_1' ) ) return true;
		return this.mystate?.includes( 'C' ) || this.captain?.isMe || false;
	}

	get canAdmin() {
		if( this.strid==='demo' || ( FANTGAMES && this.id==='team_1' ) ) return true;
		return this.mystate?.includes( 'C' ) || this.mystate?.includes( 'D' ) || this.admins?.includes( UIN ) || false;
	}

	// Specific functions about me and User
	get isMe() {
		return this.id===UIN;
		// return this.itemid==='user_' + UIN;
	}

/*
	getAvatarBg() {
		return User.getAvatarBackgroundImage( this.id );
	}
*/

	get getPicture() {
		if( this.picture ) return this.picture;
		if( this.officialfoto ) return this.officialfoto;
		if( this.name?.[0]==='@' ) return this.name.slice( 1 ).toLowerCase();
		if( this.isrobot() )
			return '/svg/icons/robot.svg'; // 'robot'; // `${IMGEMBEDPATH}/svg/icons/robot.svg`;
		if( this.avt_postfix /*&& !FANTGAMES && !NEOBRIDGE*/ )
			return `${this.id}`; // ${this.avt_postfix}
		// У пользователя в качестве аватара покажем его букву
		if( +this.id && this.name && this.name[0]!=='@' )
			return `/svg/avatars/sign.svg#${this.name.split( ' ' ).pop()[0].toUpperCase()}`;
		if( this.objectid==='team' ) return 'nophoto';
		return undefined;
	}

	get currency() {
		return window.currency( this.currsymbol );
	}

	get isFreetrial() {
		return this.freetrial===true || ( Date.now()/1000 < +this.trial?.free ) || false;
	}

/*
	get isDemomode() {
		// return false;
		return this.currsymbol==='DEMO';
	}
*/

/*
	get isDemomodeover() {
		return false;
		// return this.isDemomode && (+this.trial?.demo - Date.now() / 1000)<0;
	}
*/

	get imgorder() {
		return this.picture && '0' || this.avt_postfix && '1' || '2';
	}

	get hasPicture() {
		return this.picture || this.avt_postfix || false;
	}

	setPicture( avt, postfix, official ) {
		if( avt && this.picture===avt ) return;
		if( !avt && !postfix ) {
			if( +this.id && this.name ) { /* будем показывать букву */ }
			else if( this.avt_postfix===postfix ) return;
		}
		this.picture = avt;
		this.store( 'setPicture' );
		if( postfix ) this.avt_postfix = postfix;
/*
		if( this.avt_postfix && !this.avt_postfix.includes( '?' ) ) {
			log( `Need fetch ${this.id} because avt_postfix is ${this.avt_postfix}` );
			this.fetch();
		}
*/
		if( official ) this.officialfoto = official;
		setTimeout( () => {
			for( let el of $$( `[data-origin='${this.itemid}']` ) ) {
				if( el.tagName==='IMG' )
					el.setMagicUser( this );
				else
					el.dataset.imgorder = this.imgorder;
			}
		}, 250 );
		document.dispatchEvent( new CustomEvent( 'avatarupdated', { detail: this } ) );

		if( elephCore?.auth?.uid===this.id ) {
			log( 'Updating credentials' );
			elephCore.auth.info.avatarid = avt;
			fire( 'saveauthinfo' );
		}
		return true;
	}

	setRating( gameid, rating ) {
		if( !gameid ) return;
		let ar = rating.toString().split( /[\:\s]/ ),
			o = ar.length>1 ? {
			rating: ar[1],
			rank: ar[0]
		} : {
			rating: ar[0]
		};
		if( !this.games ) this.games = new Map;
		this.games.set( gameid.toLowerCase(), o );
	}

	doStore() {
		// log( `User storing ${this.id}` );
		if( this.loading==='LOADING' || this.fetching ) return;
		this.storeTimeout = null;
		if( !db ) return;
		// Не будем сохранять всё подряд. Создадим новый объект-копию, который и сохраним
		let st = { id: this.id };
		for( let f of User.#storedFields )
			if( this[f] ) st[f] = this[f];
		if( this.cap ) st.capid = this.cap.id;
		try {
			return db.transaction( ["users"], "readwrite" ).objectStore( 'users' ).put( st, this.id );
		} catch( e ) {
			log( 'IDB storeUser: ' + JSON.stringify( e ) );
		}
	}

	store( reason ) {
		// if( reason ) log( `User store ${this.id}: ${reason}` );
		if( User.#nostoreflag || this.storeTimeout ) return;		// Уже ждем сохранения
		if( this.id.startsWith( 'tour_' ) ) return; // Турниры пока не сохраняем
		if( (+this.id)<0 ) return;		// Отрицательные (временные) игроки не сохраняем
		this.storeTimeout ||= setTimeout( this.doStore.bind( this ), 0 );
	}

	static async fetchBySet() {
		if( !fetchSet.size ) return;
		let ar = Array.from( fetchSet );
		if( !ar.length ) return;
		fetchSet.clear();
		if( window.log ) log( `/get_head ${JSON.stringify( ar )}` );
		let r = await API( `/get_head`, { ids: ar }, 'internal' );
		if( !r?.ok ) {
			if( window.log ) log( `/get_head ${JSON.stringify( ar )} failed: ${JSON.stringify( r )}` );
			for( let id of ar ) loaded( id );
			return;
		}

		r.result.forEach( x => User.set( x.id ).fetchResult( x ) );

		// Removal deleted or wrong objects
		r.notfound?.forEach( x => {
			User.get( x )?.deleteLocal();
		});

		for( let id of ar ) loaded( id );
	}

	static async fetchAll() {
		User.fetchTimerId = null;
		this.fetchBySet();
	}

	static needFetch() {
		if( User.fetchTimerId ) return;
		User.fetchTimerId = setTimeout( User.fetchAll.bind( User ), 1000 );
		log( 'Make all fetch for User in 1sec' );
	}

/*
	async getFull() {
		return this.fetch( true );
	}
*/

	get itemid() {
		return +this.id? 'user_' + this.id : this.id; // this.objectid + '_' + this.numericid;
	}

	get objectid() {
		let idx = this.id.indexOf( '_' );
		if( idx>=0 ) return this.id.slice( 0, idx );
		return 'user';
	}

	get numericid() {
		return +this.id || +(this.id.split( '_' )[1]);
	}

	async deleteLocal() {
		this.#deleted = true;
		let r;
		try {
			log( `Deleting user ${this.itemid}...` );
			r = db.transaction( ["users"], "readwrite" ).objectStore( 'users' ).delete( this.id );
		} catch( e ) {
			log( 'deleteLocal ' + JSON5.stringify( e ) );
			return;
		}
	}

	async fetch( now ) {
		if( this.#deleted ) return;
		if( this.id==='0' || (+this.id)<0 ) return;
		if( this.fetching ) return;
		if( !now || LOCALTEST ) {
			if( !this.numericid ) {
				// if( LOCALTEST ) debugger;
				// return;
			}
			fetchSet.add( this.id );
			User.needFetch();
			return;
		}
		this.fetching = true;
		if( window.log ) log( 'Fetch ${this.objectid} ' + request );
		// let r = await API( `/${this.objectid}_data/${this.id}` );
		let r = await API( `/get_head`, { ids: [ this.id ] } );
		delete this.fetching;
		if( !r ) {
			if( window.log ) log( 'Fetch ${this.objectid} ' + this.id + ' failed' );
			loaded( this );
			return;
		}
		let data = r[0] || r;
		this.fetchResult( data );
	}

	#delayUpdate;
	fetchResult( data ) {
		if( !data?.id ) return;
		if( window.log ) log( `Fetched ${this.objectid} ${this.id}: ${JSON.stringify( data )}` );
		// document.dispatchEvent( new CustomEvent( 'userupdate', { detail: this } ) );
		this.lastfetchtime = Date.now();
		// this.store( 'fetchResult' );
		let name = data.showname || data.name;
		name && this.setShowName( name );
		if( data.avt_postfix && ( FANTGAMES || NEOBRIDGE ) ) delete data.avt_postfix;
		this.setPicture( data.picture || data.avatarid, data.avt_postfix, data.officialfoto );
		this.setOfficial( data.fio );
		for( let f of [ 'members', 'admins', 'currsymbol', 'strid', 'trial', 'entrance_balance', 'currency' ] )
			if( f in data ) this.setFieldValue( f, data[f], 'fetch-changed' );
		// if( data.members ) this.setGlobal( 'members' );
		loaded( this );
		// this.store();
	}

	updateDOM() {
		if( Object.keys( this.#delayUpdate ) )
			updateOrigin( this.itemid, this.#delayUpdate );
		this.#delayUpdate = null;
	}

	static setByObject( u ) {
		let user = User.set( u.id );
		if( !user ) return;
		if( u.name ) user.setShowName( u.name );
		if( u.picture ) user.setPicture( u.picture );
		return user;
	}

	static set( mixed, options ) {
		if( !mixed ) return;
		if( typeof mixed==='object' ) return User.setByObject( mixed );
		let blocked = /\[(.+)\]/.exec( mixed );
		if( blocked )
			mixed = blocked[1];
		if( !mixed || mixed==='?' || mixed==='*' ) return null;
		// if( +mixed==-1 ) return null;
		let s = mixed.toString().split( ':' ),
			id = s[0];
		if( !id ) return null;
		id = id.replace( '.', '_' );
		if( id==='-' ) {
			// if( LOCALTEST ) debugger;
			return null;
		}
		// Объекты только для игроков и команд
		if( id.startsWith( 'user_' ) ) id = id.split( '_' )[1];
		if( !(+id) && !id.startsWith( 'team_' ) ) return null;
		let sname = (s[1] && (window.localize && localize( s[1] ) || s[1]))
			|| options?.showname;
		// id = +id || id;
		let usr = all.get( id );
		if( usr ) {
			// if( !usr.id ) usr.id = id;
			if( !usr.name && sname ) usr.setShowName( sname );
			if( s[2] && s[2].length===32 ) usr.setPicture( s[2] );
			if( s[3] ) usr.setOfficial( s[3] );
		} else {
			// if( (+id)<=0 ) return;
			usr = new User( id, sname, options?.picture, options?.nopicture );
			if( s[3] ) usr.setOfficial( s[3] );
			if( usr.name && usr.picture )
				usr.store( 'mix parsed ' + mixed );
		}
		if( s[4] && s[4].length===2 )
			usr.setRegion( s[4] );

		// if( !plr.showName ) {}

		return usr;
	}

	static async update( head ) {
		// Обновим пользователя имеющимися свежими данными
		let id = head?.id || head?.uid;
		if( id ) return;
		let user = await this.whenloaded( id );
		user.setPicture( head.picture || head.avatarid, head.avt_postfix, head.officialfoto );
		user.setShowName( head.name || head.nick || head.showname );
		user.setOfficial( head.fio );
	}

	static get myself() {
		if( !UIN ) {
			User.me = null;
			return;
		}
		if( User.me?.id===UIN ) return User.me;
		User.me = User.set( UIN );
		return User.me;
	}

	static get( id ) {
		if( id==='myself' ) return User.myself;
		if( id instanceof User ) return id;
		if( id && !+id ) id = id.replace( 'user_', '' );
		return id && all.get( id );
	}

	static getLoad( id ) {
		if( id instanceof User ) return id;
		if( id && !+id ) id = id.replace( 'user_', '' );
		if( loadset.has( id ) ) return User.get( id );
		return new Promise( async resolve => {
			await User.whenloaded( id );
			resolve( User.get( id ) );
		} );
	}

	static setTeam( id ) {
		if( !id ) return;
		if( id.toString().startsWith( 'team_' ) ) return this.set( id );
		return this.set( 'team_' + id );
	}

	static getTeam( id ) {
		return this.get( 'team_' + id );
	}

	static getNick( id ) {
		if( !id ) return "";
		let plr = User.set( id );
		if( !plr ) return '';
		if( plr.name ) return plr.name;
		if( plr.emailid ) return plr.emailid;
		return '';
	}

	static getAvatar( id ) {
		let u = User.get( id );
		return u?.picture || null;
	}

	static setAvatars( o ) {
		for( let ar of o ) {
			let uid = ar[0].toString(),
				avt = ar[1],
				plr = User.set( uid );
			if( plr.picture===avt ) continue;
			plr.setPicture( avt );
		}
	}

	static whenloaded( id ) {
		if( !id ) return;
		if( typeof id==='object' ) {
			return Promise.all( id.map( x => User.whenloaded( x ) ) );
		}
		id = id.toString().replace( 'user_', '' );
		if( loadset.has( id ) ) return;
		this.set( id );
		return new Promise( resolve => {
			let map = loadresolve.get( id );
			if( !map ) loadresolve.set( id, (map = new Set) );
			map.add( resolve );
		})
	}

	static #delaySet = new Set;
	static #delayID;
	// static needUpdateDOM( user ) {
	// }

	static updateDOM() {
		User.#delayID = null;
		for( let user of User.#delaySet )
			user.updateDOM();
		User.#delaySet.clear();
	}
}

function loaded( id ) {
	let u = User.get( id );
	id = u.id;
	if( loadset.has( id ) ) return;
	loadset.add( id );
	u.loading = 'DONE';
	// Костыль. If democlub is loaded update mybalance for unlogged
	if( u.isDemoclub && +u.entrance_balance && !UIN )
		setGlobalData( `${u.itemid}_user_.balance`, u.entrance_balance )
		// this.setMe( 'balance', +this.entrance_balance );
	fire( 'firstloaded', id );
	let resolves = loadresolve.get( id );
	if( !resolves ) return;
	for( let f of resolves ) f( u );
	loadresolve.delete( id );
}

window.User = User;
window.modules.user = User;

dispatch( 'loggedout', () => {
	User.clearMe();
});