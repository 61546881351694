import './magic.js';
import Lang from './lang.js'
// import Auth from "./mods/auth";
/* 0- nodebug, 1- logging, 2- debug actions (only for testing!) */
"use strict";

window.GETparams = new URLSearchParams( location.search );
if( location.pathname.startsWith( '/viewer?' ) || GETparams.get( "game" ) || GETparams.get( "solo" ) ) window.SOLO = true;

window.modules ||= {};
window.addModule = ( name, module ) => {
	window.modules[name] = module;
	fire?.( 'newmodule', name );
};

window.TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
window.STANDALONE = window.matchMedia( '(display-mode: standalone)' ).matches;
window.INFRAME = window.top!==window.self;
window.ADMIN = false;
window.MYFANTS = { value: 0 };

window.$ = function( value ) {
	return document.body?.querySelector( value );
}
window.$$ = function( value ) {
	return document.body?.querySelectorAll( value ) || [];
}
Element.prototype.$up = function( value ) {
	let ar = value.split( '^' );
	if( ar.length>1 )
		return this.closest( ar[0] )?.querySelector( ar[1] );
	return this.querySelector( ar[0] );
}

Element.prototype.$ = function( value ) {
	return this.querySelector( value );
}

Element.prototype.$$ = function( selector ) {
	return this.querySelectorAll( selector );
}

window.log_start = str => {
};
window.log_complete = () => {
};
let okEmoji = ['👌', '👍', '🆗', '🙆', '✅'];
window.getOkEmoji = () => okEmoji[Math.floor( Math.random() * okEmoji.length )];
window.lastInteractTime = Date.now();
window.elephCore ||= null;
window.petitionUrls = new Map;

let lastLogTime,
	__log = console.log;
let log = console.log = window.log = ( str, param ) => {
	if( !str )
		str = "  --EMPTY--";
	const t = Date.now() - STARTTIME,
		ts = Math.floor( t / 1000 / 60 ) + ':' + Math.floor( t / 1000 ) % 60 + ':' + (t % 1000);
	let hist = '';
	if( DEBUG && typeof str==='string' && str.startsWith( '; Input error' ) ) debugger;
	if( param==='fromserver' ) {
		if( lastLogTime && t - lastLogTime>10000 ) hist += `<b>${ts}</b> `;
		hist += str + (str.endsWith( '\n' ) ? '' : '\n');
		if( DEBUG ) __log( ts + ' ' + str.slice( 0, -1 ) );
	} else {
		if( str[0]==='\n' ) str = str.slice( 1 );
		if( param==='toserver' )
			hist = ">>> " + str + "\n";
		else
			hist = "--- " + ts + " " + str + "\n";
		if( DEBUG ) {
			if( param==='toserver' )
				console.info( ts + ' >>' + str );
			else if( param==='warn' )
				console.warn( ts + ' >>' + str );
			else
				__log( ts + ' ' + str );
			if( typeof str==='object' )
				bugReport( 'log Object ' + JSON.stringify( str ) );
		}
	}

	window.historyStr += hist;
	lastLogTime = ts;
	let maxlength = 300 * 1024;
	if( historyStr.length>maxlength ) window.historyStr = window.historyStr.slice( -maxlength );
};

document.documentElement.classList.add(
	("ontouchstart" in document.documentElement) ? 'touch' : 'no-touch' );

window.STARTTIME = Date.now();

let invisibleBody;
window.invisibleBody = () => {
	invisibleBody ||= construct( '.display_none', document.body );
	return invisibleBody;
}

const href = window.location.href;
window.TIMESERVERDIFF = 0;
window.TESTER = window.LOCALTEST = href.includes( '192.168' ) || href.includes( '/local.' ) || href.includes( 'localhost:' );
window.DEBUG = LOCALTEST || href.includes( "test." ) || href.match( /localhost/ );
window.CLIENT = location.hostname.startsWith( 'client.' );
if( LOCALTEST )
	log( 'LOCALTEST=true' );
window.SPHEREPATH = [...document.head.$$( 'SCRIPT' )]
	.map( x => x.src.match( /^(.*sphere\/.*\/)js/ )?.[1] )
	.find( x => !!x );
window.SPHEREVERSION = SPHEREPATH?.match( /sphere\/v\/(.*)\// )?.[1];
// if( SPHEREPATH?.includes( '/v/' ) )
	// setTimeout( checkUpdateSphere, 300000 );

window.historyStr = '';

window.LOCAL = LOCALTEST || href.protocol==='file:' || document.URL.includes( 'file:///' );
window.bridgeAPI = 'http://localhost:8080';

window.WKIMAGESET = ('webkitLineBreak' in document.documentElement.style) && window.devicePixelRatio>1;

// До полного перехода на миллисекунды приходится проверять в какой размерности пришло время
// 999999999999 -   09 сентября 2001 года 01:46:39 UTC
window.checkMS = t => +t>999999999999 ? +t : +t * 1000;

async function setExternalDomain( domain ) {
	window.DOMAIN = window.PLAYURL = window.WWWHOST = domain;
	window.APIURL = 'https://' + domain + '/_api';
	window.EXTERNALDOMAIN = true;
	window.CLIENTHOST = domain + '/_client';
	window.FANTGAMES = window.coreParams?.id==='fg';
	window.NEOBRIDGE = window.coreParams?.id==='neo';
	window.GAMBLERRU = window.coreParams?.id==='gamb';
	let realdomain = window.coreParams?.domain || 'fantgames.com';
	window.IMGPATH = 'https://s.' + realdomain;
	window.IMGEMBEDPATH = LOCAL ? '.' : IMGPATH;
	window.CSSEMBEDPATH = LOCAL ? '.' : IMGPATH;
	window.svgIconsPath = LOCAL ? '.' : IMGPATH;
	window.projectID = FANTGAMES && 'fg' || NEOBRIDGE && 'neo' || 'gamb';
	let dd = 'domaindata_' + domain.replace( '.', 'A' );
	let data, ready;
	try {
		data = JSON.parse( localStorage[dd] );
	} catch(e) {}
	let invcode = location.search.length > 10 && !location.search.includes( '=' ) && location.search.slice( 1 );

	if( !data || invcode ) {
		data = await API( 'getdomaininfo', {
			domain: domain,
			invitations: window.invitationsKnown && [ ...invitationsKnown ],
			invitation_code: invcode
		}, 'internal' );
		if( data?.ok ) {
			if( data.root[0]==='C' ) data.team = User.setTeam( data.root.slice( 1 ) );
			localStorage[dd] = JSON.stringify( data );
			ready = true;
		}
	}

	// window.APIURL = DEBUG && localStorage.apiurl || `https://${DEBUG && 'test' || ''}api.${domain}`;
	// window.API2URL = 'https://api2.' + domain;
	window.domainData = data;
	log( `ExtDomain=${domain}, FG=${FANTGAMES}, data=` + JSON.stringify( data ) );
	if( ready ) fire( 'domaindataready' );
	window.DEBUG = true;
}

function setDomain( domain ) {
	log( 'Setdomain: ' + domain );
	if( !domain ) {
		domain = window.location.hostname.replace( /\w+\./, '' );
		if( domain==='localhost' || !domain ) {
			if( href.includes( 'fantgames' ) ) domain = 'fantgames.com';
			else if( href.includes( 'gambler' ) ) domain = 'gambler.ru';
			else if( domain!=='localhost' )
				return;
			let docdomain = document.body.dataset.domain;
			if( docdomain ) domain = docdomain;
			// Set domain later from config
			// 	return;
			// domain = window.FANTGAMES? 'fantgames.com' : 'gambler.ru';
		}
	}
	// Check base domains
	if( ![ 'fantgames.com', 'gamblergames.com', 'gambler.ru', 'neobridge.eu', 'playelephant.com', 'localhost' ].includes( domain ) ) {
		setExternalDomain( window.location.hostname );
		return;
	}
	if( window.DOMAIN===domain ) return;

	window.DOMAIN = domain;
	window.PLAYURL = 'https://www.' + domain + '/';
	// if( !LOCAL ) document.domain = domain;
	window.WWWHOST = 'https://www.' + DOMAIN;
	window.CLIENTHOST = 'https://client.' + DOMAIN;
	window.FANTGAMES = domain.includes( 'fantgames.com' ) || window.coreParams?.id==='fg';
	window.NEOBRIDGE = domain.includes( 'neobridge.eu' ) || window.coreParams?.id==='neo';
	window.PLAYELEPHANT = domain.includes( 'playelephant.com' );
	window.GAMBLERRU = domain.includes( 'gambler' );
	window.IMGPATH = 'https://s.' + domain;
	// Коррекция для альтернативного www-домена
	if( location.href.includes( 'www1.' ) ) IMGPATH = 'https://s1.' + domain;
	window.IMGEMBEDPATH = LOCAL ? '.' : IMGPATH;
	window.CSSEMBEDPATH = LOCAL ? '.' : IMGPATH;
	window.svgIconsPath = LOCAL ? '.' : IMGPATH;

	let apihost = window.location.hostname;
	if( apihost==='localhost' ) apihost = 'www.' + domain;
	window.APIURL = DEBUG && localStorage.apiurl || 'https://' + apihost + '/_api';
	if( window.GAMBLERRU || [ 'www.gambler.ru', 'www.gamblergames.com' ].includes( location.hostname ) )
		APIURL = 'https://api.' + DOMAIN;
	if( ( LOCALTEST || location.hostname.startsWith( 'test.' ) ) && DOMAIN!=='neobridge.eu' && !location.hostname.includes( 'neobridge' ) )
		APIURL = 'https://testapi.' + DOMAIN;
	// if( LOCALTEST ) APIURL = 'https://testapi.fantgames.com';

	window.API2URL = 'https://api2.' + domain;
	const mirrors = [ 'gambler.ru', 'gamblergames.com'],
		me = mirrors.indexOf( DOMAIN );
	if( me>=0 ) window.ALTERDOMAINREGEXP = new RegExp( mirrors[1 - me].replace( '.', '\\.', 'g' ) );
	log( `Domain=${domain}, FG=${FANTGAMES}` );

	window.projectID = FANTGAMES && 'fg' || NEOBRIDGE && 'neo' || 'gamb';
}

window.setCoreParams = p => {
	window.coreParams = p;
	window.projectID = p.id.capitalize();

	if( !window.EXTERNALDOMAIN )
		setDomain( coreParams.domain );
};

// Domain has to be set immediately
setDomain();

window.splitUTF = function( s ) {
	const string = String.fromCodePoint( 128514, 32, 105, 32, 102, 101, 101, 108, 32, 128514, 32, 97, 109, 97, 122, 105, 110, 128514 );
	return Array.from( string );
}
window.htmlsuits = { s: '♠️️', c: '♣', d: '🔶', h: '❤️', n: '{NT}', a: '{ALLTRUMPS_}' };
window.emoSuits = { s: '♠️️', c: '♣', d: '🔶', h: '❤️' };
window.emoAllSuits = '♠️️♠♤♣♧🔶♢♦❤️♥♡';
window.suitFromEmo = emo => {
	let str = 's♠️️s♠s♤c♣c♧d🔶d♢d♦h❤️h♥h♡', idx = str.indexOf( emo );
	if( idx>=0 ) return str[idx - 1];
	return emo;
}
window.atnt = { n: '{NT}', a: '{ALLTRUMPS_}' };

window.mediaSuperWide = window.matchMedia( '(min-aspect-ratio: 15/10)' );
window.narrowPortraitMedia = window.matchMedia( 'screen and (max-aspect-ratio: 15/20)' );
window.narrowMedia = window.matchMedia( 'screen and (max-width: 400px) and (max-aspect-ratio: 15/20)' );

window.allChats = sessionStorage['allchats'] || '';

// A more performant, but slightly bulkier, RFC4122v4 solution.  We boost performance
// by minimizing calls to random()
// noinspection JSConstructorReturnsPrimitive
window.generateUUID = () => {
	let _chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split( '' ),
		_random = Math.random,
		i = 0, uuid = new Array( 36 ), rnd = 0;

	uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
	uuid[14] = '4';

	for( ; i<36; ++i ) {
		if( i!==8 && i!==13 && i!==18 && i!==14 && i!==23 ) {
			if( rnd<=0x02 ) {
				rnd = 0x2000000 + (_random() * 0x1000000) | 0;
			}
			rnd >>= 4;
			uuid[i] = _chars[(i===19) ? ((rnd & 0xf) & 0x3) | 0x8 : rnd & 0xf];
		}
	}
	return uuid.join( '' ).toLowerCase();
};

window.fastUUID = () => {
	var lut = [];
	for( var i = 0; i<256; i++ ) {
		lut[i] = (i<16 ? '0' : '') + (i).toString( 16 );
	}

	function e7() {
		var d0 = Math.random() * 0xffffffff | 0;
		var d1 = Math.random() * 0xffffffff | 0;
		var d2 = Math.random() * 0xffffffff | 0;
		var d3 = Math.random() * 0xffffffff | 0;
		return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] +/*'-'+*/
			lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] +/*'-'+*/lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] +/*'-'+*/
			lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] +/*'-'+*/lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
			lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
	}

	return e7();
}

window.onerror = ( message, url, lineNumber ) => {
	log( "ERROR: " + message + "\n" + url + ":" + lineNumber );
};

window.UUID = localStorage.UUID;
window.invitationsKnown = new Set( localStorage.invitations_known && JSON.parse( localStorage.invitations_known ) || [] );

if( !UUID ) {
	window.UUID = localStorage['UUID'] = generateUUID();
	log( 'Generated UUID is ' + UUID );
}
document.cookie = 'uuid=' + UUID + '; path=/; samesite=lax';
if( sessionStorage.length===0 )
	localStorage['launches'] = (+localStorage['launches'] || 0) + 1;

window.readCookie = function( name ) {
	let reg = "(?:^|; )" + name.replace( /([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1' ) + "=([^;]*)";
	let matches = document.cookie.match( new RegExp( reg ) );
	return matches ? decodeURIComponent( matches[1] ) : undefined;
};
window.AUTH = GETparams.get( 'authorization' );
window.APP = GETparams.get( 'app' );
if( !AUTH ) window.AUTH = readCookie( 'authorization' );
if( AUTH ) sessionStorage.authorization = AUTH;
else AUTH = sessionStorage.authorization;
log( 'Readed AUTH: ' + window.AUTH );
window.UIN = AUTH?.split( ':' )[0];
// window.NOADUIN = 0;
window.GUESTUIN = UIN || sessionStorage.GUESTUIN;
if( !+UIN ) UIN = AUTH = undefined;
if( !window.MYNAME ) window.MYNAME = 'Me';
window.myself = { uin: UIN, _reason: 'INIT' };
// Удалим локальную куку authorization
window.lastButton = sessionStorage.lastbutton;
if( window.lastButton ) sessionStorage.lastbutton = '';
if( GETparams.get( 'screentheme' ) ) document.body.dataset.theme = GETparams.get( 'screentheme' );

window.isGuest = () => !UIN || window.ME?.type==='uuid' || false;

window.capitalize = str => str[0].toUpperCase() + str.slice( 1 ).toLowerCase();
String.prototype.capitalize = function( lowerize ) {
	if( !this.length ) return this;
	return this[0].toUpperCase() + ( lowerize? this.slice( 1 ).toLowerCase() : this.slice( 1 ) );
}

window.isChildOf = ( test, parent ) => {
	for( ; test; test = test.parentNode )
		if( test===parent ) return true;
	return false;
};

//-------------------------

window.createblock = function( classline, subelems ) {
	const o = construct( classline );
	const p = { o_panel: o };
//    o.s_object = p;
	for( let i = 0; i<subelems.length; i++ ) {
		const e = construct( '.' + subelems[i] );
		p['o_' + subelems[i]] = e;
		o.appendChild( e );
	}
	return p;
};

/*
window.createelement = ( classname, param ) => {
	let tag = typeof param==='string' && param || 'div';
	const o = document.createElement( tag || 'div' );
	if( classname ) o.className = classname;
	if( param instanceof HTMLElement ) param.appendChild( o );
	return o;
};

*/
window.construct = construct;		// Keep it! arguments works only with function

window.html = ( html, ...args ) => {
	if( !html ) return;
	let template = document.createElement( 'template' );
	template.setContent( html );
	let el = template.content.children[0];
	for( let arg of args ) {
		if( typeof arg==='function' ) el.onclick = arg;
		else if( arg instanceof Node ) arg.appendChild( template.children.length===1? el : template.content );
	}
	return el;
}

window.closeBigWindows = withad => {
	for( let win of $$( '.bigwindow.visible' ) )
		if( withad || win.id!=='ad' ) win.hide();
};

window.hideWindowsByOrigin = origin => {
	for( let win of $$( `.bigwindow[data-byorigin='${origin}']` ) )
		win.hide();
}

let bigZ = 100000;

function checkValidity( top ) {
	let valid = !top.$( '*:invalid' );
	for( let b of top.$$( '[data-checkvalidity],button[default]' ) )
		b.disabled = !valid;
}

let bigWindows = [];
window.makeBigWindow = ( options, str ) => {
	cssInject( 'bigwindow' );
	let html = str || '';
	if( typeof options==='string' ) {
		html = options;
		options = null;
	}
	options ||= {};
	let box;
	if( options.id ) {
		box = $( '#' + options.id );
		if( box ) return box;
	}
	if( options.repeatid )
		box = $( '#' + options.repeatid );
	if( !box ) {
		box = construct( '.fade.bigwindow' );
		let id = options.id || options.repeatid;
		if( id ) box.id = id;
		box.options = options;
		if( options.closeonclick ) box.dataset.closeonclick = options.closeonclick;
		if( options.noads ) box.classList.add( 'noads' );
		if( options.closeable ) box.classList.add( 'closeable' );
	}

	if( options.html )
		html += options.html;

	if( options.head==='standard' || options.title || options.picture ) {
		box.classList.add( 'closeable' );
		let pic = options.picture && fillMagicIMG( options.picture, 24 ) || '',
			title = `${pic}<span style='margin-left: 0.3em; white-space: initial'>${options?.title || '&nbsp;'}</span>`;
		html = `<div class="${options.simple ? '' : 'leftedit editform'} column ${options.fullsize && 'fullsize' || ''}" 
			style='gap: 10px; padding: 0; max-width: min(35rem,100%); position: relative'>
			<-close->
			<div class='title flexline' style='align-self: start; grid-area: title; padding: 10px 20px 0 10px; column-gap: 5px; _font-size: 1rem'>${title}</div>
			<div style='border-bottom: 0.5px solid gray; margin: 0;'></div>
			<div class='winbody column' style='overflow: hidden auto; grid-area: rest; width: 100%; 
				_max-height: min(27em,80vh)'>${html}</div>
			</div>`;
	} else if( options.closebutton ) {
		html = (html || '') + `<span class='control grayhover closebutton icon righttop display_none visible' 
			style='width: 2rem; height: 2rem' data-closeselect='close'></span>`;
		box.classList.add( 'closeable' );
	}
	html = html.replace( '<-close->', `<span class='control grayhover icon righttop invertdark' 
					style='right: 5px; top: 5px; width: 2rem; height: 2rem;
					background-image: url(${IMGEMBEDPATH}/svg/icons/highlight_off_black_24dp.svg)' data-closeselect='close'></span>` );

	if( html ) box.setContent( html );

	if( box.$( '[data-closeselect="cancel"]' ) )
		box.classList.add( 'closeable' );

	if( html.includes( 'data-checkvalidity' ) || box.$( 'button[default]' ) ) {
		box.dataset.validityform = 1;
		checkValidity( box );
	}
	box.setWinBody = options => {
		let pic = options.picture && fillMagicIMG( options.picture, 24 ) || '',
			title = `${pic}<span style='margin-left: 0.3em';>${options?.title || '&nbsp;'}</span>`;
		box.$( '.title' ).html( title );
		box.$( '.winbody' ).html( options.html );
	}
	box.body = str => box.$( '.winbody' )?.html( str );
	// box.defaultButton = box.$( 'button.default' );
	// if( options.onclick )
	// 	box.addEventListener( 'click', options.onclick );
	box.addEventListener( 'keydown', e => {
		if( e.key==='Enter' && !e.ctrlKey ) {
/*
			if( e.currentTarget.defaultButton ) {
				e.currentTarget.defaultButton.click();
				return false;
			}
*/
			let def = e.currentTarget.$( 'button.default' );
			if( def && !def.disabled ) {
				def.click();
				return false;
			}
		}
	} );
	box.onclick = async e => {
		let t = e.target;
		/*
				if( t===e.currentTarget ||
					( e.currentTarget.options && e.currentTarget.options.selectandhide ) ) {
					// Клик на фон, убираем его
					// e.target.classList.remove( 'visible' );
					e.currentTarget);
					e.stopPropagation();
					return false;
				}
		*/
		if( t?.dataset['apirequest'] ) {
			let json = {};
			// Выполним API request, и если он успешен, закроем окно
			for( let el of e.currentTarget.querySelectorAll( '[name],[data-name]' ) ) {
				json[el.name || el.dataset['name']] = el.value || el.dataset['value'];
			}
			let res = await API( t.dataset['apirequest'], json );
			if( res.ok ) {
				e.currentTarget.hide();
			}
			return;
		}
	}

	box.promiseShow = () => {
		return new Promise( resolve => {
			box.onhideresolve = resolve;
			box.show();
		} );
	}
	(options.parent || document.body)?.appendChild( box );
	if( options.parent ) box.inner = true;		// bigwindow inside other object
	return box;
};

window.getTopBigWindow = function( easyclosable ) {
	let top;
	for( let i=bigWindows.length; !top && i--; ) {
		let t = bigWindows[i];
		if( !t ) break;
		if( t.classList.contains( 'transparent' ) ) continue;
		if( t.isVisible() ) top = t;
	}
	if( !top ) {
		let tops = $$( '.bigwindow.visible:not(.transparent)' );
		top = tops[tops.length - 1];
	}
	// ! returns false if top big window is not easyclosable
	if( top && easyclosable && top.$( '[data-closeselect="cancel"]:not([data-closebyescape])' ) )
		top = false;
	return top;
}

window.closeTopBigwindow = ( el, doresolve ) => {
	el = el?.closest( '.bigwindow' ) || getTopBigWindow( true );
	if( !el ) return el;
	el.hide( doresolve );
	return el;
};

function construct( def ) {
	let tag = 'div', parent, onclick, classes = '', content = typeof def==='string' && def;
	for( let i = 0; i<arguments.length; i++ ) {
		let arg = arguments[i];
		if( typeof arg==='function' ) onclick = arg;
		else if( arg instanceof HTMLElement ) parent = arg;
		else if( arg instanceof DocumentFragment ) parent = arg;
		else if( ['img', 'button', 'input', 'textarea', 'span', 'a', 'label', 'video', 'iframe', 'audio', 'hr'].includes( arg ) ) tag = arg;
	}
	let el;
	if( typeof def==='string' ) {
		// if( def[0]==='#' || def[0]==='.' || def[0]==='[' ) {
		let ar = def.split( ' ', 1 );
		let rtag = /^([a-zA-Z]*)[#\.\[ ]/.exec( ar[0] );
		if( rtag && rtag[1] ) tag = rtag[1];
		el = document.createElement( tag );
		content = def.slice( ar[0].length + 1 );
		let re = /(#|\.|\[)([_=\{\}\-a-zA-Z0-9]*)[\]]*/g, res;
		while( (res = re.exec( ar[0] ))!==null ) {
			if( !res[0] ) continue;
			if( res[1]==='#' ) el.id = res[2];
			else if( res[1]==='.' ) classes += ' ' + res[2];
			else if( res[1]==='[' ) {
				let pair = res[2].split( '=' );
				el.setAttribute( pair[0], localize( pair[1] ) );
			}
			// }
		}
	}
	if( !el )
		el = document.createElement( tag );
	if( onclick ) el.onclick = onclick;
	el.className = classes.trim();
	if( content ) el.setContent( content );
	if( parent ) parent.appendChild( el );
	return el;
}

window.createtree = function( prefix, o ) {
	let res = {},
		fields = o.split( ', ' );
	res.holder = construct( '.' + prefix + '_holder' );
	for( let k of fields ) {
/*
		let ar = k.split( '@' );
		let id = ar[1] || k;
		let tag = ar[1] ? ar[0] : null;
*/
		res[k] = construct( '.' + k, res.holder );
	}
	return res;
};

window.setTranslatePosition = function( o, x, y ) {
	setStyleTranslate( o, x, y );
};

window.setTransformMatrix = function( o, m ) {
	o.style.transform = 'matrix( ' + m.join( ',' ) + ' )';
};

window.setStyleTranslate = function( o, p, y ) {
	let t = null;
	if( p ) {
		if( p.x ) {
			// t = 'translate3(' + p.x + 'px,' + p.y + 'px )';
			t = 'translate3d(' + p.x + ', ' + p.y + ', 0 )';
			if( p.scale )
				t += ' scale3d( ' + p.scale + ', ' + p.scale + ', 1 )'
		} else {
			if( (typeof p==='string') && p.includes( '%' ) )
				t = 'translate3d(' + p + ',' + y + ',0)';
			else
				t = 'translate3d(' + p + 'px,' + y + 'px,0)';
		}
	}
	o.style.transform = t;
	// o.baseTransform = t;
};

window.pad00 = val => val<10 && ('0' + val) || val;

window.timerHHMMSS = function( v, text, sec ) {
	if( v<0 ) return "00:00";
	v = Math.floor( v / 1000 );
	if( v<0 ) v = 0;
	let s = v % 60;
	const m = (v - s) / 60;
	let mm = m % 60;
	const h = (m - mm) / 60, hday = h % 24, days = (h - hday) / 24;
	if( days ) text = 1;
	if( !text ) {
//				if( s<10 ) s = '0' + s;
//				if( mm<10 ) mm = '0' + mm;
		return (h ? pad00( h ) + ':' : '') + pad00( mm ) + ':' + pad00( s );
	}
	let str = days ? (days + 'd') : '';
	str += (str ? ' ' : '') + (hday && hday + 'h ' || '') + (mm && mm + (hday && 'm' || 'min') || '');
	if( sec && !hday ) str += ' ' + s + 's';
	// return str.includes( '{' )? localize( str ) : str;
	return str;
};

window.timerHHMM = timestamp => {
	let d = timestamp ? new Date( timestamp ) : new Date;
	return pad00( d.getHours() ) + ':' + pad00( d.getMinutes() );
};

function manualAnimate( el, ar ) {
	let s = ar[ar.length - 1];
	for( let k in s ) el.style[k] = s[k];
}

window.elementAnimate = ( el, ar, params ) => {
	if( !ar[0] ) ar[0] = el.lastTransform;
	// log( 'A: ' + JSON5.stringify( ar[0] ) + ' => ' + JSON5.stringify( ar[1] ) );
	if( params?.fill==='forwards' )
		el.lastTransform = ar[1];		// Всегда сохраняем последнюю анимацию
	if( ('animate' in el) && params && ('duration' in params) ) {
		// if( document.hidden ) log( 'ZERO ANIMATE ' + el.str + ' (' + ((el.owner&&el.owner.id)||'?') + ')' );
		try {
			for( let k in ar[0] )
				if( !(k in ar[1]) ) ar[1][k] = ar[0][k];
			if( window.skipAnimation ) params.duration = 0;
			// if( DEBUG )
			// 	log( 'Animating ' + JSON.stringify( ar[0] ) + ' ===> ' + JSON.stringify(ar[1]));
			let anim = el.animate( ar, params );
			if( anim ) return anim;
		} catch( e ) {
			if( LOCALTEST ) debugger;
		}
	}
	manualAnimate( el, ar );
};

let animId,
	animQueue = new Set;
function setWaitAnimation( element, options ) {
	element.onScreen = 'idle';
	element.waitAnimation = options;
	animQueue.add( element );
	if( animId ) return;
	animId = window.requestIdleCallback?.( animationCallback ) || requestAnimationFrame( animationCallback );
}

function animationCallback() {
	// Has to check is this cards are already hidden
	for( let element of animQueue ) {
		let anim = element.waitAnimation;
		if( !anim ) {
			log( `While waiting idle element ${element.str} was dropped` );
			continue;
		}
		element.onScreen = true;
		stepAnimate( element, anim );
	}
	animQueue.clear();
	animId = null;
}

function stepAnimate( element, options ) {
	// log( `Animating ${element.str} ${options?.hide&&' HIDE'||''} ` );
	element.show();
	element.lastTransform = null;
	if( options.hide )
		element.afterTransition = {
			hide: true
		}
	else
		element.afterTransition = null;	// Should be zeroed for cancelling unexpected other transition end
	let smooth = element.onScreen;
	element.style.transition = smooth? null : 'none';
	// if( animate && element.lastOwner!==element.owner )
	// 	element.style.zIndex = 1000;
	element.style.transform = options.transform;
	// if( LOCALTEST ) log( `Putting ${element.str} to ${str}` );
	element.style.opacity = options.hide? '0' : '';
	element.onScreen = options.transform;
	element.lastOwner = element.owner;
	if( smooth && options.onfinish && 'ontransitionend' in element ) {
		element.ontransitionend = options.onfinish;
		useronfinish = null;
	} else
		// Если не поддерживается анимация, или не поддерживается ontransitionend, вызовем сразу же
		options.onfinish?.();
}

// Animate move element to new left-top position
// options {} may contains:
//   virtual: true // if element could not be visible (for cards)
//   hide: true   //  if element must be hidden at the animation end
// doAnimate use "function" to have "arguments" in scope
window.doAnimate = async function ( element, left, top, options, scale ) {
	if( !(element instanceof HTMLElement) && LOCALTEST )
		debugger;
	if( options==='hide' && LOCALTEST )
		debugger;
	if( typeof left==='number' ) left = left.toString() + 'px';
	if( typeof top==='number' ) top = top.toString() + 'px';
	let str = 'translate3d( ' + left + ', ' + top + ', 0 )';
	// let str = 'translate( ' + left + 'px, ' + top + 'px )';
	if( str.includes( 'NaN' ) ) {
		bugReport( `BadAnimate ${str}: ${JSON.stringify(arguments)}; ${object.className}, ${JSON.stringify( object.dataset )}` );
		return 'error';
	}
	let useronfinish = options?.onfinish;
	if( scale ) str += ' scale( ' + scale + ' )';
	let end = { transform: str },
		useTransition = element.classList.contains( 'solid_card' );
	if( options==='virtual' || options?.mode==='virtual' ) {
		// Nothing to do now, but next time start moving from this position
		// Если прикуп виден наблюдателю, не делаем вообще ничего
		log( `Setting lastTransform for ${element.str} to ${JSON.stringify(end)}`)
		if( !element.visible ) {
			element.lastTransform = end;
		}
		return;
	}
	let hide = options==='hide', animation;

	// start.opacity = object.visible ? 1 : 0;
	// end.opacity = hide ? 0 : 1;

	// try {
		if( useTransition ) {
			// if( element.onScreen===str ) return;	// Already here
			if( element.style.transform===str ) return;	// Already here
			let anim = {
				transform: str,
				...options
			};
			if( element.lastTransform ) {
				// Move to this place without animation
				if( element.lastTransform!=='wait' ) {
					log( `Putting ${element.str} to startPoint ${JSON.stringify(element.lastTransform.trasform)}` );
					element.style.transition = 'none';
					element.style.opacity = '0';
					element.style.transform = element.lastTransform.transform;
				}
				// await new Promise( requestAnimationFrame );
				setWaitAnimation( element, anim );
				return;
			}
			stepAnimate( element, anim );
			return;
		} else {
			let start = element.lastTransform || { transform: end.transform };
			if( !start.visibility ) start.visibility = 'visible';
			end.visibility = hide ? 'hidden' : 'visible';

			animation = elementAnimate( element, [start, end],
				{
					duration: options?.noanimation ? 0 : 150,
					fill: 'forwards'
				} );
			if( animation ) {
				if( !useronfinish && (!options || !options.flyover) ) return;
				if( 'onfinish' in animation ) {
					let keepz = element.style.zIndex;
					element.style.zIndex = 999;				// пролетаем над всеми
					animation.onfinish = () => {
						element.style.zIndex = keepz;
						useronfinish?.();
						useronfinish = null;
					}
					return;
				}
			}
		}
	// } catch( err ) {
	// 	bugReport( 'elementAnimate failed: ' + str );
	// }
	if( hide ) {
		element.lastTransform = null;		//
		// element.visibility = false;
		element.owner = null;
	} else {
		if( !useTransition )
			element.lastTransform = end;			// Уже установлен
		// element.dataset['transform'] = JSON.stringify(end);
		// element.visible = true;
	}
	// Если не поддерживается анимация, или не поддерживается onfinish, вызовем сразу жe
	useronfinish?.();
};

window.addEventListener( 'transitionend', e => {
	let t = e.target,
		act = t.afterTransition;
	if( act ) {
		// log( `Transition end for ${t.str} with ${JSON.stringify(act)}` );
		if( act.style ) for( let k in act.style )
			t.style[k] = act.style[k];
		if( act.hide ) {
			// log( `Hiding el ${t.str||''} [${t.className}]` );
			t.hide();
			// For future cards purpose can use element.onScreen = null;
		}
		t.afterTransition = null;
	}
});

window.HTML5DRAGDROP = false; // ('draggable' in Panel) || ('ondragstart' in Panel && 'ondrop' in Panel)
log( 'HTML5/Drag&Drop ' + (HTML5DRAGDROP ? 'YES' : 'no') );
log( `Lang=${navigator.language} of ${navigator.languages}` );

// trick from https://developer.mozilla.org/en-US/docs/Web/Events/resize
function checkVmin() {
	let vmin100 = document.body?.clientHeight && Math.min( document.body.clientHeight, document.body.clientWidth ) + 'px';
	if( !vmin100 ) return;
	log( 'vmin100: ' + vmin100 );
	document.documentElement.style.setProperty( '--vmin100', vmin100 );
}

let retransform;

function initResize() {
	let running = false;
	let func = function( e ) {
		log( 'RESIZE: ' + document.documentElement.clientWidth + 'x' + document.documentElement.clientHeight );
		checkVmin();
		if( running ) return;

		for( let el of $$( '[data-transformbyclick]' ) ) el.style.transform = '';
		clearTimeout( retransform );
		retransform = setTimeout( () => {
			for( let el of $$( '[data-transformbyclick]' ) )
				checkTransformed( el );
		}, 300 );

		// Keep height if mobile and width unchanged
		running = true;
		if( e==='now' )
			dispatchEvent( new CustomEvent( 'winresize' ) );
		else
			setTimeout( () => dispatchEvent( new CustomEvent( 'winresize' ), 300 ) );
		running = false;
	};
	addEventListener( 'resize', func );
	checkVmin();
	if( document.readyState==='complete' ) func( 'now' );
	// addEventListener( 'orientationchange', func );
}

HTMLElement.prototype.toggleVisible = function() {
	this.makeVisible( !this.classList.contains( 'visible' ) );
}

window.makeVisible = ( object, value, param ) => {
	if( !object || !object.holder ) return;
	if( value===undefined ) value = true;
	if( value===object.visible ) return;
	object.visible = value;
	object.holder.classList.toggle( 'visible', value );
	if( value ) object.onShow?.( param );
	!value && object.onHide?.( param );
	return true;
};

Element.prototype.setAttributes = function( o ) {
	for( let k in o ) this.setAttribute( k, o[k] );
};

HTMLElement.prototype.makeVisible = function( value ) {
	return this[(!!value) ? 'show' : 'hide']();
};

HTMLElement.prototype.setOnlyChildVisible = function( element ) {
	for( let el of this.$$( ':scope > .visible' ) )
		if( el!==element ) el.hide();
	element?.show();
}

HTMLElement.prototype.toggleVisible = function() {
	this[this.classList.contains( 'visible' ) ? 'hide' : 'show']();
}

HTMLElement.prototype.isVisible = function() {
	return this.classList.contains( 'visible' );
}

HTMLElement.prototype.isVisibleTotal = function() {
	if( this.classList.contains( 'display_none' ) || this.classList.contains( 'fade' ) ) {
		if( !this.classList.contains( 'visible' ) ) return false;
	}
	// Если родителя нет, то виден, если это уже HTML (иначе он не в DOM)
	if( !this.parentElement ) return this===document.documentElement;
	return this.parentElement.isVisibleTotal();
}

HTMLElement.prototype.show = async function( callbackParams ) {
	if( this.isVisible() ) return;
	if( this.dataset.onlyonevisible )
		this.parentElement.$( `.visible[data-onlyonevisible='${this.dataset.onlyonevisible}']` )?.hide();

	// if( LOCALTEST && this.classList.contains( 'solid_card' ) ) {
	// 	log( `Showing card ${this.str}` );
	// }

	if( this.classList.contains( 'bigwindow' ) ) {
		// Hide waiting spinners
		dropWaitingSpinners();

		if( !this.parentElement ) document.body?.appendChild( this );

		await cssInject( 'bigwindow' );
		let ar = this.querySelectorAll( 'input[autofocus],input[data-autofocus]' );
		if( !ar.length ) ar = this.querySelectorAll( 'input:not([type="checkbox"]):not([type="hidden"]),textarea' );
		if( ar.length===1 ) {
			if( ![ 'file', 'datetime-local', 'number' ].includes( ar[0].type ) )
				setTimeout( () => { ar[0].focus(); ar[0].setSelectionRange?.( 0, 10 ) }, 200 );
			this.dispatchEvent( new Event( 'input' ) );
		}
		if( this.parentElement!==document.body && !this.inner ) {
			document.body.appendChild( this );
			// https://stackoverflow.com/questions/61017477/requestanimationframe-inside-a-promise
			// await new Promise( requestAnimationFrame );
		}
		this.style.zIndex = bigZ++;

		let idx = bigWindows.indexOf( this );
		if( idx>=0 ) bigWindows.splice( idx, 1 );
		bigWindows.push( this );

		// Всегда при наличии окна должно быть быть перехват BACK browser
	}
	this.classList.add( 'visible' );

	this.onShow?.( callbackParams );
		// this.visible = true;
	// let input = this.querySelector( 'input[autofocus]' );
	// if( input ) setTimeout( () => input.focus(), 200 );
	// this.dispatchEvent( new Event( 'input' ) );
	return true;
};

HTMLElement.prototype.hide = function( e ) {
	if( this.__hiding ) return;
	if( !this.isVisible() ) return;
/*
	if( LOCALTEST && this.classList.contains( 'solid_card' ) ) {
		log( `Hiding card ${this.str}` );
	}
*/
	this.classList.remove( 'visible' );
	// this.visible = false;
	let resolve	 = this.onhideresolve;
	if( resolve ) this.onhideresolve = null;
	if( this.classList.contains( 'bigwindow' ) ) {
		bigZ--;
		let idx = bigWindows.indexOf( this );
		if( idx>=0 ) bigWindows.splice( idx, 1 );
		// Send refreshing message to top window
		if( bigWindows.length ) {
			let backwin = bigWindows[bigWindows.length - 1];
			backwin?.onreturn?.();
		}
		delay( 'bigwindowclose' );
	}
	this.__hiding = true;
	resolve?.( e || 'cancel', this );

	this.onHide?.( e, this );
	this.__hiding = false;
	return true;
};

window.parsePhase = str => {
	let [, phase, , param] = str.match( /(.*?)(\s|$)(.*)/ );
	if( param ) param = JSON.parse( param );
	return [phase, param];
};

initResize();

// Bug report
window.bugReport = ( msg, e ) => {
	if( LOCALTEST ) debugger;
	if( typeof msg==='object' ) msg = undefined;
	if( msg ) {
		if( msg.includes( "read the 'cssRules' property" ) ) return;
		if( msg.includes( '-extension' ) || msg.includes( 'require is not defined' )  || msg.includes( 'Scripted is not defined' ) ) return;
	}
	import( './mods/bugreport.js' ).then( module => module.default( msg, e ) );
};

window.callBugReport = () => window.bugReport(); // It is correct. No params! user call

// Events
let handlers = {},
	delayed = {},
	delayedFunctions = new Set, executeFunctions;

export function dispatch( eventid, func ) {
	if( Array.isArray( eventid ) ) return eventid.forEach( () => window.dispatch( el, func ) );
	if( eventid==='ready' && document.readyState==='complete' ) {
		func( 'ready' );
		return;
	}
	let hs = handlers[eventid] || (handlers[eventid] = new Set);
	if( !func ) {
		return new Promise( resolve => {
			resolve.hitOnce = true;
			hs.add( resolve );
		} );
	}
	hs.add( func );
};

window.dispatch = dispatch;

window.goLocation = loc => {
	if( loc==='demo' && window.DEMOCLUBDELETED ) return toast( '{Notfound}' );
	if( !loc.toString().includes( 'http:/' ) && loc[0]!=='/' ) loc = '/' + loc;
	if( loc==='/' + window.domainData?.root ) loc = '/';
	log( 'Relocating to ' + loc );
	location.replace( loc );
}

window.setHash = hash => {
	window.SKIPPOPSTATE = true;
	location.hash = hash;
	window.parent?.postMessage( 'sethash ' + hash, '*' );
}

window.fire = ( id, event ) => {
	// log( 'Fire event ' + id );
	let hh = handlers[id];
	if( !hh ) return;
	for( let f of hh ) {
		if( f.hitOnce ) hh.delete( f );
		f( event );
	}
};

/*
let delayed = new Map;
window.delayCall = ( func, timeout ) => {
	if( delayed.has( func ) ) return;
	let id = setTimeout( () => delayed.delete( func ), timeout );
	delayed.set( func, id );
}
*/

window.undelay = func => {
	delayedFunctions.delete( func );
}

window.delay = ( id, comment ) => {
	if( !id ) return;
	if( typeof id==='function' ) {
		if( delayedFunctions.has( id ) ) return;
		delayedFunctions.add( id );
		if( executeFunctions?.delete( id ) ) {
			// Execute it later
			// return;
		}
		// log( 'Adding delay function ' + id.name + ' ' + (comment||'') );
		id = '*';
	}
	if( !delayed[id] )
		window.postMessage( id, '*' );
	return true;
};

function onmessage( e ) {
	if( e.data==='key_escape' ) {
		// Дочерний фрейм прислал ESC
		if( closeTopBigwindow() ) {
			e.stopPropagation();
			return false;
		}
		return;
	}
	if( typeof e.data==='string' && e.data.startsWith( 'openurl ' ) ) {
		// Дочерний фрейм хочет перейти на адрес
		if( closeTopBigwindow() ) {
			e.stopPropagation();
			let url = e.data.slice( 'openurl '.length );
			if( url.startsWith( '/shop' ) ) {
				// Go Shopping
				shopping( url.slice( 5 ).replace( '/' ) );
				return;
			}
			let s = url.replace( '/tour/', '' );
			fire( 'golocation', s );
			return false;
		}
		return;
	}
	delayed[e.data] = null;
	if( e.data==='*' ) {
		if( !delayedFunctions.size ) return;	// No delayed
		executeFunctions = delayedFunctions;
		delayedFunctions = new Set;
		for( let f of executeFunctions ) {
			if( f._comment ) log( "DELAY: " + f._comment );
			f( e );
		}
		executeFunctions = null;
		return;
	}
	fire( e.data, e );
}

// window.addEventListener( 'dragstart', e => {
// if( e.target.dataset['movable'] ) {
// 	e.dataTransfer.setData( 'text/plain', 'test text' );
// }
// });

window.addEventListener( 'message', onmessage );

// Fictive adding history with the same address

if( !window.SOLO ) {
	if( history.state==='my' ) history.replaceState( '', null );
	history.pushState( 'my', null );
}

let setMyLoc;
window.setMyLocation = loc => {
	if( history.state!=='my' ) {
		setMyLoc = loc;
		history.forward();
	} else {
		if( loc==='/' + window.domainData?.root ) loc = '/';
		log( 'Location. Replacing state to ' + loc );
		history.replaceState( 'my', null, loc );
	}
}

window.addEventListener( 'popstate', e => {
	// Window popstate (Back button in browser)
	if( window.SKIPPOPSTATE ) {
		window.SKIPPOPSTATE = null;
		return;
	}
	log( `Popstate type=${e.type}, state=${e.state}` );
	if( e.state==='my' ) {
		// Отложенное изменение location в заголовке (был выполнен history.forward)
		if( setMyLoc ) {
			log( 'Location. Delayed replacing state to ' + setMyLoc );
			history.replaceState( 'my', null, setMyLoc );
			setMyLoc = null;
		}
		return;
	}
	if( !closeTopBigwindow() ) {
		// No opened window. Wants to go back really
		if( elephCore?.goBack() ) return;
		history.back();
		return;
	}
	// Back to the 'my' state
	if( !window.SOLO ) history.forward();
} );

// window.addEventListener('devicemotion', function(event) {
// 	log( 'ACCEL: ' + JSON.stringify( event.acceleration ) );
// });

/*
		window.addEventListener('deviceorientation', function(event) {
			log('Magnetometer: '
				+ 'alpha=' + event.alpha
				+ 'beta=' + event.beta
				+ 'gamma=' + event.gamma
			);
		});
*/

function checkChrome() {
	let old = window.bowser && bowser.chrome && !bowser.check( { chrome: '57' }, true );
	if( old ) {
		// Old Chrome version
		let link = window.device ? "//play.google.com/store/search?q=chrome&c=apps" : "//chrome.google.com",
			link1 = link;
		let win = html( `<div class='abs100' style='z-index: 100000; position: fixed'></div>`, document.body ),
			chrome = `Chrome ${bowser.version} (min 57)`;
		if( window.cordova && bowser.android ) {
			// link = "http://play.google.com/store/apps/details?id=com.google.android.webview";
			link = "market://details?id=com.google.android.webview";
			link1 = "https://play.google.com/store/apps/details?id=com.google.android.webview&hl=ru";
			chrome = 'Android WebView';
		}
		win.innerHTML = `<h1>You need to upgrade your ${chrome}</h1>
					otherwise visual artifacts are possible.<br><br>
					<a href="${link}" class="button" style="font-size: x-large; background: green; color: white; padding: 0.1em 0.2em">Upgrade</a>
					<br><br>
					<a href="${link1}">${link1}</a><br>
					<br>
					<br>
					`;
		construct( '.graybutton.largefont.inlineblock {Close}', win, () => win.remove() );
		// <button action="close" style="font-size: 1rem">Continue</button>
		// 	win.querySelector( 'button[action="close"]' ).onclick = () => {
		// 		win.style.display = 'none';
		// 	};
		/*
						if( DEBUG )
								bugReport( 'oldversion ' + bowser.name + ' ' + bowser.version
									+ ' on ' + bowser.osname + '/' + bowser.osversion
									+ (bowser.mobile&&'[MOBILE]'||bowser.tablet&&'[TABLET]'||'') );
		*/
	}
}

function updateData( element, value ) {
	if( element.dataset.payload ) element.dataset.payload = value || '';
	else if( element.dataset.datavisibility ) element.makeVisible( !!value );
	else element.setContent( value || '' );
}

// If requestIdleCallBack is not defined
window.requestIdleCallback ||= func => requestAnimationFrame( func );

window.setGlobalData = ( name, value ) => {
	// value = needTransliterate( value );
	function update( cmt ) {
		let cc = 0;
		for( let o of document.querySelectorAll( '[data-name="' + name + '"]' ) ) {
			updateData( o, value );
			cc++;
		}
		log( `Global update ${cmt || 'bytime'} ${name}: ${value} (${cc})` );
		return cc;
	}

	if( !update( 'NOW' ) )
		setTimeout( update, 250 );
};

window.toDatasetString = function( json ) {
	let str = '';
	for( let k in json ) str += ` data-${k}='${json[k]}'`;
	return str;
}

window.getBigWindow = el => {
	return el.closest( '.bigwindow' );
}

window.collectParams = ( element, options ) => {
	options ||= {};
	let form = element.closest( 'form, .bigwindow' ),
		params = {},
		mkserv = options.serverstr,
		str = '';
	// for( ; form && form.tagName!=='FORM' && !form.classList.contains( 'bigwindow' ); )
	// 	form = form.parentElement;
	if( form ) {
		for( let el of form.querySelectorAll( '[name], [data-name]' ) ) {
			let name = el.name || el.dataset.name;
			if( !name || name.startsWith( '__' ) ) continue;
			if( options.names && !options.names.includes( name ) ) continue;
			if( options.valid && ('validity' in el) && !el.validity.valid ) continue;
			let v = el.dataset.value ?? el.value ?? '',
				vv = v;
			if( el.type==='checkbox' ) {
				v = el.checked;
				vv = v ? '1' : '';
			}
			if( el.value && el.hiddenvalue )
				v = el.hiddenvalue;
			params[name] = v;
			if( mkserv )
				str += ` ${name}=\`${vv.replaceAll( '`', '' )}\``;
		}
	}
	if( mkserv && str ) params.serverstr = str;
	return params;
};

async function doClick( t ) {
	if( t.disabled ) return;
	if( t.dataset.confirm ) {
		if( !(await askConfirm( t.dataset.confirm ) ) ) return;
	}
	let json = {
		origin: t.closest( '[data-origin]' )
	};
	if( t.dataset.collectparams ) {
		json.params = collectParams( t );
	}
	log( 'API request ' + t.dataset.api + ' ' + JSON.stringify( json ) );
	t.classList.add( 'processing' );
	t.setSpinner( true );
	t.disabled = true;
	let res = await API( t.dataset.api, json, t );
	t.setSpinner( false );
	t.disabled = false;
	t.classList.remove( 'processing' );
	if( res?.ok && t.dataset.closeonsuccess ) {
		t.closest( '.bigwindow' )?.hide();
	}
}

HTMLElement.prototype.cancelTransformation = function() {
	this.classList.remove( 'transformed' );
	checkTransformed( this );
}

HTMLElement.prototype.transformationClick = function() {
	this.classList.toggle( 'transformed' );
	clearTimeout( this.transformTimeoutId );
	checkTransformed( this );
	if( this.classList.contains( 'transformed' ) && this.dataset.transformbacktimeout )
		this.transformTimeoutId = setTimeout( () => {
			this.classList.remove( 'transformed' );
			checkTransformed( this );
		}, +this.dataset.transformbacktimeout*1000 );
}

window.checkTransformed = function( t ) {
	let tr = '';
	if( t.classList.contains( 'transformed' ) ) {
		tr = t.dataset.transformbyclick;
		let goal,
			ar = t.dataset.transformtarget?.split( ' ' );
		if( ar ) {
			goal = t.closest( ar[0] );
			if( ar[1] ) goal = goal.$( ar[1] );
		}
		if( tr.includes( 'center' ) ) {
			// Прокидываем на центр, вычисляя координаты
			let rect = t.getBoundingClientRect(),
				grect = goal?.getBoundingClientRect(),
				cx = '50vw', cy = '50vh';
			if( grect ) {
				cx = grect.x + grect.width / 2 + 'px';
				cy = grect.y + grect.height / 2 + 'px';
			}
			tr = `translate( calc( ${cx} - ${(rect.right+rect.left)/2}px ), calc( ${cy} - ${(rect.bottom+rect.top)/2}px ) ) scale( ${goal? 5 : 10} )`;
		}
	}
	t.style.transform = tr;
}

HTMLElement.prototype.goSheet = function( sheet ) {
	sheet ||= this.dataset.gosheet || '0';
	// Помечаем кнопки (если он есть)
	let base = this.tagName==='BUTTON'? this.parentElement : this;
	for( let el of base.$$( '* > button[data-gosheet]' ) )
		el.classList.toggle( 'selected', el.dataset['gosheet']===sheet );
	// Находм уровень вкладок (sheets)
	for( let parent = base; parent; parent = parent.parentElement ) {
		let sh = parent.$( `[data-sheet='${sheet}'],[data-sheetprefix='${sheet}']` );
		if( !sh ) continue;
		for( let el of sh.parentElement.children )
			el.makeVisible( el===sh );
		parent.$( '.slidesheets' )?.scrollIntoView( {
			block: "start",
			inline: "nearest",
			behavior: 'auto'
		} );
		let tabwin = parent.closest( '.tabwindow' ) || parent.parentElement;
		tabwin.$( '.back' )?.makeVisible( sheet!=='0' );
		let title = sh.title || sh.dataset.title;
		if( title ) tabwin.$( '.title' )?.setContent( title );
		return sh;
	}
}

document.addEventListener( 'paste', event => {
	log( 'Paste event: ' + JSON.stringify( event.data ) );
} );

window.showInfo = async ( target, origin ) => {
	if( !target ) return;
	origin ||= target.dataset.origin;
	if( !origin ) return;
	let insideclub = target.closest( '[data-insideclub]' )?.dataset.insideclub;
	let ar = origin.split( /[\._]/ );
	if( ar[0]==='user' ) {
		if( insideclub ) {
			import( './mods/team.js' ).then( mod => {
				mod.teamMember( User.getTeam( insideclub ), User.get( ar[1] ), {
					openinfoiferror: true
				} );
			} );
			return;
		}
	}
	if( ar[0]==='user' || ar[0]==='team' ) {
		// Клик на пользователе, откроем его инфу
		// e.stopPropagation();
		let mod = await import( './mods/userinfo.js' );
		mod.default( ar[0] + '_' + ar[1], target?.closest( '[data-gameid]' )?.dataset.gameid,
			target?.closest( '[data-userclickparams]' )?.dataset.userclickparams );
	}
}

let controlsTimeout;
document.addEventListener( 'click', async function( e ) {
	window.lastInteractTime = Date.now();
	let t = e.target;
	if( !t ) return;
	let someClicks = false;
	// log( 'Click control ' + JSON.stringify( t.dataset ) );
	dropWaitingSpinners2s();

	if( t.dataset.spinner ) {
		t.setSpinner( 'wait' );
	}

	// Кнопка "показать-скрыть пароль"
	if( t.tagName==='BUTTON' && t.parentElement?.classList.contains( 'password' ) ) {
		let inp = e.target.previousElementSibling || e.target.closest( 'div' )?.$( 'input' );
		if( !inp ) return;
		inp.type = inp.type==='text' ? 'password' : 'text';
		// Курсор поля ввода надо вернуть на конец поля ввода
		requestAnimationFrame( () => {
			inp.selectionStart = inp.value.length;
		} );
		e.preventDefault();
		return;
	}

	if( t.dataset.api ) {
		// отправляем API-запрос. Дополнительные данные собираем из всех остальных полей FORM или bigwindow
		log( `Click api ${t.dataset.api}` );
		e.preventDefault();				// Обычное действие по кнопке (отправка формы) действовать не должно
		e.stopPropagation();
		doClick( t );
		someClicks = true;
		return;
	}

	if( t.tagName==='A' ) {
		if( t.dataset['actionid']==='closebigwindow' ) {
			closeTopBigwindow( t );
			e.stopPropagation();
			return;
		}
		// Перехват браузерных обработок кликов с кнопками. Нужен только в приложении
		if( t.href.match( /^(https?|market):\// ) ) {
			if( window.cordova ) {
				window.open( t.href, '_system' );
			} else if( !window.STATIC )
				localBrowser( t.href );
			else return;
			e.preventDefault();
			return;
		}
		someClicks = true;
	}
	if( t.classList.contains( 'bigwindow' ) ) {
		// Клик на фон, убираем его. Не делаем этого, если есть кнопка Отмена
		if( !t.$( '[data-closeselect="cancel"]' ) ) {
			e.stopPropagation();
			closeTopBigwindow( t ); // t.hide();
			return false;
		}
		someClicks = true;
	}

	if( t.tagName==='LABEL' && !t.for && t.firstElementChild ) t = t.firstElementChild;

	if( t.dataset.magictype &&
		(!t.dataset.canedit || t.dataset.canedit?.split( ' ' ).includes( AUTH ))
		&& (t.dataset.editable==='always'
			|| (t.dataset.editable==='yes' && !t.dataset.magicid)
		) ) {
		e.stopPropagation();
		e.preventDefault();
		import( /* webpackChunkName: "upload" */
			/* webpackMode: "lazy" */ './mods/upload.js' ).then( module => {
			module.default.click( e );
		} );
		return;
	}

	// if( DEBUG && !window.ONCE ) window.ONCE = t.dataset.datatype = t.dataset.editable = 'gameset';
	if( t.dataset.datatype && t.dataset.editable ) {
		// Редактирование
		e.stopPropagation();
		e.preventDefault();
		import( './mods/tools.js' ).then( module => {
			module.editElement( t );
		} );
		return;
	}

	// Пройдем вверх в попытке что-то сделать
	for( ; t; t = t.parentElement ) {
		if( t.dataset.hint ) {
			// Single-time hint by clicking this object
			if( window.hint?.showBig( t.dataset.hint, true ) ) {
				e.stopPropagation();
				return;
			}
		}

		if( t.dataset.gosheet ) {
			t.goSheet();
			return;
		}
		if( t.dataset.closeselect ) {
			// Уберем окно, в котором выбрали, возможно вызовем resolve
			t.closest( '.bigwindow' )?.hide( t.dataset.closeselect );
			someClicks = true;
			// return;
		}

		if( t.dataset.openchat ) {
			import( './mods/userinfo.js' ).then( mod => {
				mod.openChat( t.dataset.openchat );
			});
			return;
		}

		if( t.dataset.transformbyclick ) {
			t.transformationClick();
			someClicks = true;
			return;
		}

		if( t.dataset.clickexpandto ) {
			let el = t.$up( t.dataset.clickexpandto );
			el?.toggleVisible();
			someClicks = true;
			e.stopPropagation();
			return;
		}

		if( t.dataset.serveraction ) {
			if( !elephCore ) return;
			// For server requests authorization required
			if( !UIN ) return checkAuth( 'complete' );
			if( 'disabled' in t ) t.disabled = true;
			else t.classList.add( 'disabled' );
			await Promise.any( [ elephCore.sendPlay( decodeURIComponent( t.dataset.serveraction ) ), sleep( 2000 ) ] );
			if( 'disabled' in t ) t.disabled = false;
			else t.classList.remove( 'disabled' );
			return;
		}

		if( t.dataset.hideclosest )
			return t.closest( t.dataset.hideclosest )?.hide( e );

		if( t.dataset.openlocalbrowser ) {
			localBrowser( t.dataset.openlocalbrowser );
			return;
		}

		if( t.dataset.edit ) {
			(await import( './mods/tools.js' )).editapi( t );
			return;
		}

		if( t.dataset.share ) {
			execute( e, t, {
				execute: 'inv.invite',
				url: location.href,
				title: t.dataset.share
			} );
			return;
		}

		if( t.dataset.buy ) {
			shopping( t.dataset.buy.split( ':' )[0] );
			return;
		}

		if( t.dataset.golocation )
			return goLocation( t.dataset.golocation );

		if( t.dataset.execute ) {
			// Выполняем скрипт.функцию с этим событием
			execute( e, t );
			return;
		}

		if( t.dataset.gameaction ) {
			window.Swiper?.current.classGame?.externalAction( t.dataset.gameaction, e );
			return;
		}

		if( t.dataset.action==='showsettings' ) {
			// t.waitForAction( 'settings' );
			window.showSettings?.( e );
			return;
		}

		if( t.dataset.toclipboard ) {
			toClipboard( t );
			e.preventDefault();
			return;
		}

		if( t.classList.contains( 'logout' ) && t.tagName!=='A' ) {
			// Разлогиниваемся
			signout();
			e.stopPropagation();
			e.preventDefault();
			return;
		}

		if( t.dataset.popupinfo ) {
			// Покажем окно с большой информацией
			makeBigWindow( {
				repeatid: 'popupinfo',
				title: '💡 ' + ( t.title || t.dataset.title || '{Information}' ),
				html: `<PRE style='padding: 0 1em'>${decodeURIComponent( t.dataset.popupinfo )}</PRE>`
			} ).show();
			e.stopPropagation(); e.preventDefault();
			return;
		}

		if( !window.INFRAME && t.dataset.magicid && t.dataset.editable!=='always' && !t.classList.contains( 'nozoom' ) ) {
			let ta = t.parentElement;
			while( ta && ta.tagName!=='A' && !ta.dataset.href ) ta = ta.parentElement;
			let href = ta?.href || ta?.dataset.href;
			if( href && href!==document.location.href ) return;
			// if( t.parentElement.tagName==='A' && t.parentElement.href && t.parentElement.href!==document.location.href ) return;
			e.stopPropagation();
			e.preventDefault();
			previewImg( e, '', `//storage.playelephant.com/images/preview/512/${t.dataset['magicid']} 1x,
					//storage.playelephant.com/images/preview/1024/${t.dataset['magicid']} 2x,`, t );
			return false;
		}

		if( !t.onclick && !t.closest( '[data-noinfo]' ) && t.dataset.origin && !t.closest( '[data-closeselect]' ) ) {
			showInfo( t );
			return;
		}

		if( t.tagName==='INPUT' || t.tagName==='BUTTON' || t.tagName==='A' || t.tagName==='DETAILS' ) break;
		if( t.dataset.closeonclick ) {
			if( t.dataset.closeonclick==='empty' && someClicks ) return;
			t.hide();
			return false;
		}
		// Moved from common.js
		if( !CLIENT && t.dataset.href && !(e.shiftKey)
			&& !['SUMMARY', 'INPUT', 'BUTTON'].includes( t.tagName ) ) {
			if( t.dataset.stopdebug ) {
				console.warn( 'Click ' + JSON.stringify( e ) );
				debugger;
			}
			if( t.dataset.windowname )
				window.open( t.dataset.href, t.dataset.windowname, 'resizable,width=500,height=500' );
			else if( e.ctrlKey || e.metaKey )
				window.open( t.dataset.href, '', '' );
			else {
				if( window.cordova ) {
					window.open( t.href, '_system' );
				} else if( !window.STATIC )
					localBrowser( t.href );
				else
					location = t.dataset.href;
			}

			e.stopPropagation();
			return false;
		}

		// ---- returned from common.js
		if( t.onclick )
			someClicks = true;

		if( !someClicks && t===document.body ) {
			// Добрались до последнего элемента. Включим доселе невидимые элементы - bugreport
			window.CONTROLS_ON = !window.CONTROLS_ON;
			log( 'Controls ' + (window.CONTROLS_ON ? 'ON' : 'OFF') );
			if( elephCore?.globalAttr.bugreporticonvisible ) {
				let bug = $( '#bugreporticon' );
				if( CONTROLS_ON ) {
					if( bug ) {
						bug.show();
						controlsTimeout = setTimeout( () => {
							window.CONTROLS_ON = false;
							bug.hide()
						}, 5000 );
					}
				} else {
					bug?.hide();
					clearTimeout( controlsTimeout );
				}
			}
		}
	}
} );

document.addEventListener( 'input', async function( e ) {
	let t = e.target;
	if( t.tagName==='INPUT' && t.dataset.autosize ) {
		t.size = t.value.length || 1;
	}
	if( (t.tagName==='SELECT' || t.tagName==='INPUT' || t.tagName==='TEXTAREA') ) {
		if( t.dataset.storebuttonselector ) {
			for( let o of document.querySelectorAll( t.dataset.storebuttonselector ) ) {
				o.show();
				o.disabled = false;
			}
		}
		// Validity-buttons
		let top = t.closest( '[data-validityform]' );
		if( top ) checkValidity( top );
	}

	if( t.dataset.onchangeapi ) {
		if( !t.validity || t.validity.valid ) {
			let v = t.value;
			if( t.tagName==='INPUT' && t.type==='datetime-local' ) v = t.value;
			let res = await API( t.dataset.onchangeapi, {
				origin: t.closest( '[data-origin]' )?.dataset.origin,
				name: t.name,
				value: t.value
			}, 'internal' );
			if( res.ok )
				toast( 'Ok' );
			else {
				// Восстановим прежнее значение (где его взять?)
			}
		}
	}
} );

document.addEventListener( 'keydown', async function( e ) {
/*
	if( LOCALTEST && e.key==='V' ) {
		toast( 'Testing vugraphcom http://clubs.vugraph.com/fethiyebric/boarddetails.php?event=342322&section=A&pair=7&direction=NS&board=1' );
		import( './pageparser.js' ).then( mod => mod.parse( 'http://clubs.vugraph.com/fethiyebric/boarddetails.php?event=342322&section=A&pair=7&direction=NS&board=1' ));
		return;
	}
*/
	if( e.key==='Escape' ) {
		let dd = $( '.dropdown.visible' );
		if( dd ) {
			log( 'Closing dropdown menu' );
			dd.hide();
			e.stopPropagation();
			return false;
		}
		log( 'Escape - closing top window' );
		let top = getTopBigWindow( true );
		if( !top || top.$( '[data-closeselect="cancel"]:not([data-closebyescape])' ) ) return;
		if( closeTopBigwindow() ) {
			e.stopPropagation();
			return false;
		}
		window.parent?.postMessage( 'key_escape', '*' );

	}

	if( e.key==='t' && e.ctrlKey ) {
		if( !AUTH ) return;
		let game = await ((await import( './mods/tools.js' )).selectgame());
		if( !game ) return;
		(await import( `./mods/${toureditModule()}.js` )).create( {
			game: game
		} );
		return;
	}

	// Отдадим фокус в чат, если он не захвачен
	if( e.key && e.key.length>1 ) return; // || e.ctrlKey && e.altKey && e.metaKey ) return;		// Special keys
	let atag = document.activeElement && document.activeElement.tagName;
	if( atag==="TEXTAREA" || atag==="INPUT" ) return;

	// Сначала проверим есть ли top BigWindow, ищем там
	if( !e.ctrlKey && !e.metaKey && !e.altKey ) {
		let topw = getTopBigWindow();
		if( topw ) {
			let chatinp = topw.$( '.chat_area.visible .chat_input' );
			chatinp ||= topw.$( '.display_none.visible input[default]' );
			chatinp?.focus();
		} else {
			let chatInput = window.$( '.mainarea[data-swipetop="true"] .chat_input' );
			chatInput?.focus( e );
		}
	}
} );

let toastMsg = construct( '.toast.fade', document.body ),
	toastId;
window.toast = ( str, param ) => {
	if( !str || str[0]==='#' ) return;
	let options = typeof param==='object' && param || { duration: param },
		dur = options.duration;
	if( options.chat ) str = '💬 ' + str;
	toastMsg.classList.remove( 'untranslated' );
	toastMsg.setContent( str );
	toastMsg.classList.add( 'visible' );
	if( dur==='long' ) dur = 3000;
	if( dur==='short' ) dur = 1500;
	if( !dur ) dur = 3000;
	toastMsg.classList.toggle( 'bottom', options.bottom || false );
	toastId && clearTimeout( toastId );
	toastId = setTimeout( () => toastMsg.classList.remove( 'visible' ), dur );
	log( 'Toast ' + str );
};

function onLoad() {
	window.coreParams ||= {};
	if( !window.DOMAIN ) setDomain();
	if( window.bowser ) {
		bowser.device = bowser.mobile && 'mobile' || bowser.tablet && 'tablet' || '';
		// window.bowser = bowser;
	}

	// Google tag
	if( window.googleTag && !window.EXTERNALDOMAIN ) {
		// добавим скрипт для гугла
		let script = document.createElement( 'script' );
		script.src = googleTag;
		document.head.appendChild( script );
	}

	if( !location.href.includes( 'skipcheck' ) )
		checkChrome();

	// API start
	log( 'Onload api call with APIURL ' + APIURL );
	for( let o of document.body.$$( '[data-onloadapi]' ) )
		API( o.dataset.onloadapi, null, o );

	// Prettify JSON
	for( let o of $$( 'textarea[data-type="JSON"]' ) )
		try {
			o.textContent = JSON.stringify( JSON.parse( o.textContent ), null, 5 );
		}
	catch(e) {}

	// Для корректных Media
	if( !window.ResizeObserver )
		document.body.classList.add( 'noresizeobserver' );

	for( let [ css, resolve ] of cssInjectSet )
		cssInject( css ).then( () => {
			resolve?.();
		});

	document.body.dataset.theme = localStorage.screentheme || 'auto';

	checkMyVersion();

	/*
		html( `<script async defer src="https://cdn.jsdelivr.net/npm/canvas-confetti@1.9.3/dist/confetti.browser.min.js"></script>`,
			document.head );
	*/
}

window.sleep = ms => new Promise( resolve => setTimeout( resolve, ms ) );

window.getBrowserName = () => {
	let browser = '';
	if( window.cordova ) {
		browser = '=APP= ';
		if( cordova.appName ) browser += cordova.appName + ' ';
		if( cordova.versionNumber ) browser += cordova.versionNumber + '. ';
		if( window.device ) {
			if( device.isVirtual ) browser += 'VIRTUAL ';
			browser += ` on ${device.model} (${device.platform} ${device.version})`;
		}
	} else if( window.bowser ) {
		browser += bowser.name + ' ' + bowser.version + ' on ' + bowser.osname + '/'
			+ bowser.osversion + (bowser.mobile && '[MOBILE]' || bowser.tablet && '[TABLET]' || '');
	} else if( navigator.userAgentData ) {
		browser = `${navigator.userAgentData.platform} `;
		for( let o of navigator.userAgentData.brands ) {
			browser += ' ' + Object.values( o ).join( '/' ) + " ===";
		}
	} else
		browser = navigator.platform + '; ' + navigator.userAgent;
	return browser;
}

window.getHiresIcon = ( icon, w ) => {
	if( !icon ) return '';
	if( !w ) w = 1024;
	if( icon.length===32 ) return STORAGE + `/images/preview/${w}/${icon}`;
	return IMGPATH + '/icons/hires/' + icon;
}

HTMLElement.prototype.toggleDisabled = function( val ) {
	this.classList.toggle( 'disabled', val );
	val? this.disabled = true : this.removeAttribute( 'disabled' );
}

HTMLImageElement.prototype.setMagicUser = function( uid ) {
	let user = (typeof uid==='object') && uid || window.User?.get( uid );
	// if( !user ) return;
	// Обновим под пользователя. Однако, если задана officialfoto, то не нужно трогать
	if( user?.officialfoto && this.dataset.magicid===user.officialfoto ) return;
	this.setMagic( (user?.getPicture || user?.id) || null, user?.itemid );
}

window.fillPlayerHTML = ( plr, options ) => {
	if( !plr ) return '';
	if( typeof plr!=='object' ) {
		plr = modules.user?.set( plr ) || { id: plr };
	}
	options ||= {};
	// Не надо использовать plr.itemid в качестве имени по-умолчанию, так как для игроков будет выглядеть плохо
	let mainname = options.fullname && plr.fio || plr.getShowName || plr.name || plr.title || options.name || '',
		id = plr.uin || plr.id,
		pic = plr.getPicture || plr.picture || options.picture || '',
		dataorigin = `data-origin='${plr.itemid || plr.id || ''}'` || '';

	if( options.editable && pic.includes( 'sign.svg' ) ) pic = null;
	if( !pic && mainname[0] ) {
		// if( +plr.id )
		// 	pic = `/svg/avatars/sign.svg#${mainname[0].toUpperCase()}`;
		// else
			pic = 'nophoto';
	}
	if( options.textonpicture && pic==='nophoto' ) pic = 'pixel';
	let imgstr = '';
	if( !options.nopicture ) {
		// imgstr = fillMagicIMG(plr.magicid||plr.avatarid||plr.uin||plr.id,options.size || 48, {
		imgstr = fillMagicIMG( pic || '', options.size || 48, {
			type: options.type || 'avatar',
			origin: plr.itemid || plr.id,
			nozoom: options.nozoom,
			title: mainname,
			defimg: options.defimg,
			noinfo: options.noinfo || options.photoclick==='noinfo',
			editable: options.editable,
			...options.imgoptions
		} );
		let badge = plr.count ? ` data-badge='${plr.count}' data-badgetype='counter'` : '',
			bottomname = options.bottomname && `<span class='bottomname' style='white-space: nowrap; font-size: min( 1rem, 100% )'>${mainname}</span>` || '';

		imgstr = `<div class='badge' ${dataorigin} ${badge}>${imgstr}${bottomname}</div>`;
	}
	let dataset = '';
	if( options.dataset )
		for( let k in options.dataset ) dataset += ` data-${k}='${options.dataset[k]}'`
	if( options.noinfo ) dataset += ' data-noinfo="1"';
	let stylestr = `position: relative;${options.style||''};`,
		imgorder = options.noimgorder ? '' : `data-imgorder='${plr.imgorder || ""}'`,
		daystring = plr.time && `data-daystring='${(new Date( plr.time * 1000 )).toLocaleDateString()}'` || '';
		// data-uin='${id}   - вынесено из следующей строки
	if( options.textonpicture )
		stylestr += 'border-radius:10%;overflow:hidden;'
	else
		stylestr += 'overflow:hidden;';

	let prefixstr = '';
	if( options.selectbox )
		prefixstr = `<span class='flexline checkbox' style='min-width: 1.3em; justify-content: center'>${options.selectbox==='checked'?'⬤':'◯'}</span>`;
	// Main object
	let str = `<div ${options.extra || ''} ${dataset}class='imagehead ${options.control && 'control' || ''} 
		${options.message && 'message'||''} ${options.classes || ''}' style='${stylestr}' 
		${imgorder} ${dataorigin} ${daystring} ${options.draggable?'draggable="true"':''}>
		${prefixstr}
		${imgstr}`;

	if( !options.notext ) {
		let subname = '';
		if( !options.fullname && !options.nofio ) {
			let fio = plr.officialName;
			if( fio )
				subname = `<span style='white-space: nowrap' data-name='${plr.itemid}_official'>${fio}</span>`;
		} else subname = options.descr || '';
		let dataname = plr.itemid && `data-name='${plr.itemid}.showname'` || '',
			balance = options.showbalance ? `<span style="color: var( --color_fants )" data-name='user_${UIN}_${plr.itemid}.balance' data-after=' ${plr.currency}'>${showBalance(plr.mybalance,plr.currency)}</span>` : '',
			undername = `${options.message || options.undername || plr.subtitle || plr.subname || ''}`,
			undertext = undername && `<span class='undername'>${undername}</span>`,
			mainnumberclass = (+mainname) ? (+mainname>0 ? ' increment' : ' decrement') : '',
			textposstyle = options.textstyle || '';
		//
		// Градиент на картинку накладывается из статьи https://xhtml.ru/2021/css/handling-text-over-image-css/
		// для режима показа названия прямо на логотипе
		if( options.textonpicture ) textposstyle += `position:absolute;bottom:0;color:white;width:100%;height:100%;
		align-items:center;justify-content:end;
		background:linear-gradient(    to bottom,    hsla(0, 0%, 35.29%, 0) 0%,hsla(0, 0%, 34.53%, 0.034375) 16.36%,
		hsla(0, 0%, 32.42%, 0.125) 33.34%,hsla(0, 0%, 29.18%, 0.253125) 50.1%,hsla(0, 0%, 24.96%, 0.4) 65.75%,
		hsla(0, 0%, 19.85%, 0.546875) 79.43%,hsla(0, 0%, 13.95%, 0.675) 90.28%,hsla(0, 0%, 7.32%, 0.765625) 97.43%,
		hsla(0, 0%, 0%, 0.8) 100%  );text-shadow:0 2px 3px rgb(0 0 0 / 30%);`;
		str += `<div ${options.notextblock ? '' : 'class="textblock"'} style='overflow: hidden;${textposstyle}'>
			<span class='username${mainnumberclass} hideempty' 
				style='white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%; ${options.textonpicture?' padding: 0.3em 0;':''}' 
				${dataname} >${mainname}</span>
			${subname}
			${undertext}
			${balance}
		</div>`;
	}
	if( options?.text ) str += options.text; // '<div>' + text + '</div>';
	str += '</div>';
	return localize( str, {
		notranslit: FANTGAMES || NEOBRIDGE
	} );
};

let bigBrow;
window.localBrowser = ( url, makenew ) => {
	if( !url ) return;
	let win = makenew ? null : bigBrow;
	log( 'URL received: ' + url + '. auth=' + AUTH );
	if( !win ) {
		win = makeBigWindow( `<button class='close righttop' data-closeselect='close' style='font-size: 2rem'>❌</button><iframe class='browser'></iframe>` );
		log( 'Creating local browser' );
	}
	if( !url.includes( 'http' ) ) {
		url = `https://client.${DOMAIN}${url}`;
		if( AUTH ) {
			url += url.includes( '?' ) ? '&' : '?';
			url += `authorization=${encodeURI( AUTH )}`;
		}
		if( window.cordova )
			url += '&app=1';
		let mode = localStorage.screentheme;
		if( mode && mode!=='auto' ) url += '&screentheme=' + mode;
	}
	if( !url.includes( '?' ) ) url = url.replace( '&', '?' );
	log( 'showURL: ' + url );
	win.$( 'iframe' ).src = url;
	win.show();

	if( !makenew && !bigBrow ) {
		bigBrow = win;
		bigBrow.onHide = () => {
			bigBrow.$( 'iframe' ).src = '';
		};
	}
};

window.makeClientUrl = path => {
	let url = `${CLIENTHOST}${path}`;
	if( AUTH ) {
		url += url.includes( '?' ) ? '&' : '?';
		url += `authorization=${encodeURI( AUTH )}`;
	}
	return url;
}

window.showBugButton = v => {
	if( !elephCore?.globalAttr.bugreporticonvisible ) return;
	$( '#bugreporticon' )?.makeVisible( v );
}

let smileStr = '🙂 :) :-) 😀 :D =D 😞 :( :-( 😮 :-[] 😕 :/ :-/ :S 🤐 :X 😐 :-| 😡 :-* 😉 ;v) ' +
		'♠ !s !S ❤️ !H !h 🔶 !D !d ♣ !C !c 🆚 _vs_ 🐑 :sheep: 🐏 :ram:',
	smileCodes = smileStr.split( ' ' ),
	smiles = new Map(),
	regstr = '^[' + smileCodes.filter( x => x.charCodeAt( 0 )>256 ).join( '' ) + ']$',
	smileReg = new RegExp( regstr, 'g' );
let regsmile;
window.addEventListener( 'input', e => {
	window.lastInteractTime = Date.now();
	let t = e.target,
		value = t.value;
	if( t.tagName==='TEXTAREA' && t.dataset.type==='JSON' ) {
		try {
			if( value ) JSON.parse( value );
			t.setCustomValidity( '' );
		} catch( e ) {
			t.setCustomValidity( 'Bad json' );
		}
	}
	if( t.tagName!=='INPUT' ) return;
	// ♠♥♦♣
	if( !regsmile ) {
		let smile, ar = [];
		for( let s of smileCodes ) {
			if( s.charCodeAt( 0 )>256 ) {
				smile = s;
				continue;
			}
			if( s[0]==='_' ) {
				smile = ' ' + smile;
			}
			if( s.slice( -1 )==='_' ) {
				smile = smile + ' ';
			}
			s = s.replace( /_/g, ' ' );
			smiles.set( s, smile );
			ar.push(
				s
					.replace( '\\', '\\\\' )
					.replace( '|', '\\|' )
					.replace( '*', '\\*' )
					.replace( '/', '\\/' )
					.replace( '(', '\\(' )
					.replace( ')', '\\)' ) );
		}
		let pattern = '(' + ar.join( '|' ) + ')$';
		regsmile = new RegExp( pattern );
	}
	if( t.type!=='url' ) {
		let newvalue = value.replace( regsmile, k => smiles.get( k ) || k );
		if( newvalue!==value ) t.value = newvalue;
	}
} );

window.addEventListener( 'focusout', e => {
	if( e.target.tagName==='TEXTAREA' && e.target.dataset.type==='JSON' ) {
		try {
			let j = JSON.parse( e.target.value );
			e.target.value = JSON.stringify( j, null, 5 );
		} catch( e ) { }
	}
});

HTMLElement.prototype.setChat = function( msg, author ) {
	let newmsg = msg.replace( smileReg, '<span class="emoji">$&</span>' )
		.replace( /\n/g, '<br>' );
	if( author?.id===UIN ) needTransliterate.block = 1;
	if( newmsg!==msg )
		this.innerHTML = needTransliterate( newmsg );
	else
		this.setContent( msg );
	needTransliterate.block = false;
}

let cssPath, cssModules = {}, cssPostfix;
window.cssMustWait = function( name, onload ) {
	if( !cssModules[name] ) cssInject( name );
	if( !cssModules[name] || cssModules[name].state==='loaded' ) return;
	if( onload ) cssModules[name].onloadset.add( onload );
	return true;
}

let cssInjectSet = new Set;
window.cssInject = function( name ) {
	if( cssModules[name]?.onloadset===null ) return;		// Already loaded

	return new Promise( resolve => {
		if( document.readyState!=='complete' ) {
			cssInjectSet.add( [ name, resolve ] );
			return;
		}

		if( !cssModules[name] ) {
			if( !cssPostfix ) {
				cssPostfix = document.scripts[document.scripts.length - 1].src.split( '?' )[1];
				if( cssPostfix ) cssPostfix = '?' + cssPostfix; // Math.random();
				else cssPostfix = '';
			}

			if( !cssPath ) {
				cssPath = document.querySelector( 'link[href*=".css"]' )?.href;
				let n = cssPath.lastIndexOf( '/' );
				cssPath = cssPath.slice( 0, n + 1 );
			}

			let css = document.createElement( 'link' );
			css.rel = "stylesheet";
			css.href = `${cssPath}${name}.css${cssPostfix}`;

			let s = cssModules[name] = {
				module: css,
				state: 'added',
				onloadset: new Set()
			};
			css.onload = () => {
				if( !s.onloadset ) return;
				s.state = 'loaded';
				for( let f of s.onloadset ) f?.();
				s.onloadset = null;
			}
			document.querySelector( "head" ).appendChild( css );
		}
		cssModules[name].onloadset.add( resolve );
		return;
	} );
}

// Транслитерирование строки, если необходимо
window.needTransliterate = function( str ) {
	if( !str ) return null;
	if( FANTGAMES || NEOBRIDGE ) return str;
	if( needTransliterate.block ) return str;
	if( !['ru', 'be', 'uk'].includes( window.language ) ) {
		return (function rus_to_latin( str ) {
			var ru = {
				'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
				'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i', 'й': 'j',
				'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
				'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
				'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
				'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya'
			}, n_str = [];

			str = str.replace( /[ъь]+/g, '' ).replace( /й/g, 'i' );

			for( var i = 0; i<str.length; ++i ) {
				n_str.push(
					ru[str[i]]
					|| ru[str[i].toLowerCase()]==undefined && str[i]
					|| ru[str[i].toLowerCase()].replace( /^(.)/, function( match ) {
						return match.toUpperCase()
					} )
				);
			}

			return n_str.join( '' );
		})( str );
	}
	return str;
}

let events = new Map;

window.waitFor = function( evname ) {
	let ev = events.get( evname );
	if( ev ) return Promise.resolve();
	if( !ev ) {
		ev = new Set;
		events.set( evname, ev );
	}
	return new Promise( resolve => {
		ev.add( resolve );
	} )
}

window.eventStatus = function( evname, status ) {
	let fset = events.get( evname );
	if( status ) {
		if( fset ) {
			fset.forEach( x => x() );
			fset.clear();
		}
		events.set( evname, new Set );
		return;
	}
	if( !status ) {
		events.delete( evname );
		return;
	}
}

let confirmWindow;
window.askConfirm = function( msg ) {
	if( !confirmWindow ) {
		confirmWindow = makeBigWindow( {
			id: 'confirmwindow'
		}, `<div class='vertical_form'>
				<span style='max-width: 25em'>Are you sure?</span>
				<div class='flexline' font-size='large'>
					<button name='yes' data-closeselect='yes'>{Yes}</button>
					<button data-closeselect='no'>{No}</button>
				</div>
			</div>` );
	}

	// Не используем reject, так как в вызовах await askConfirm обычно нет catch()
	return new Promise( async resolve => {
		let span = confirmWindow.$( 'span' );
		span.setContent( msg || '{Confirmation}' );
		// confirmWindow.$( '[name="yes"]' ).onclick = resolve;
		// confirmWindow.onHide = reject;
		span.style.fontSize = span.textContent.length>100 ? '1rem' : 'inherit';
		sessionStorage.showingAd = true;
		let res = await confirmWindow.promiseShow();
		if( res==='yes' ) resolve( true );
	} );
}

window.sounds = {};

window.playSound = ( sound, important ) => {
	if( !sounds[sound] ) preloadSound( sound );
	if( window.NOSOUNDS ) return;
	try {
		sounds[sound]?.play();
	} catch( e ) {
		log( 'Sound ' + sound + ' failed' );
	}
}

window.preloadSound = sound => {
	if( typeof sound==='object' ) {
		for( let s of sound ) preloadSound( s );
		return;
	}
	let s = sound;
	if( !s.includes( '.' ) ) s += '.ogg';
	let el = sounds[sound] = construct( 'audio', document.body );
	el.src = `${IMGPATH}/sounds/${s}`;
	el.preload = 'auto';
	return el;
}

window.prettyFants = val => {
	return +val>10000000 && Math.round( +val / 1000000 ) + 'M'
		|| +val>1000000 && Math.round( +val / 100000 ) / 10 + 'M'
		|| +val>5000 && Math.round( +val / 1000 ) + 'K'
		|| +val>10 && Math.round( +val ).toString()
		|| +val && val.toString() || '0';
}

window.numberWithCommas = x => x.toString().replace( /\B(?=(\d{3})+(?!\d))/g, "," );

let biginfowin, infoWin;
window.bigInfo = params => {
	cssInject( 'userinfo' );
	biginfowin ||= makeBigWindow( {
		closeonclick: true
	} );

	let s = '<div class="talkinghead">' + fillMagicIMG( params.picture, 256, null, "style='float:left; max-width: 50vw; max-height: 50vh;'" );
	s += localize( '<span>' + params.text + '</span>' ) + '</div>';
	biginfowin.html( s );
	biginfowin.show();
};

window.API = API;
window.API_WAITING_REQUESTS = 0;
async function API( req, json_source, form, options ) {
	if( !window.APIURL ) return;
	if( req[0]!=='/' ) req = '/' + req;
	let url = APIURL,
		needauth = form!=='internal' && !req.startsWith( '/sign' ) && req!=='/remind';
	if( req.includes( 'localhost' ) || req.startsWith( '/uniq/' ) || req.startsWith( '/parser/' ) ) {
		if( LOCALTEST && !url.includes( 'localhost' ) ) url = 'https://www.neobridge.eu/_api';
		needauth = false;
	}

	if( !window.AUTH && needauth ) return;		// Не отправляем запросы без авторизации
	let json = { ...json_source };
	let auth = window.AUTH,
		internal = false,
		element;
	if( form==='internal' ) {
		internal = true;
		form = null;
	}
	if( form instanceof HTMLElement ) {
		element = form;
		form = null;
	}
	json.uuid = window.UUID;
	json.authorization = auth;
	json.device = window.device || navigator.userAgentData?.platform;
	if( window.SPHEREPATH )
		json.spherepath = window.SPHEREPATH;

	if( window.device ) {
		json.deviceuuid = device.uuid;
		json.platform = device.platform;
		if( device.isVirtual ) json.virtual = true;
	}
	if( window.cordova ) {
		json.version = cordova.versionNumber;
		if( cordova.packageName ) json['packagename'] = cordova.packageName;
	}
	json.lang = elephLang?.language || navigator.language || 'en';
	json.timezone = TIMEZONE;
	json.referer = location.href;
	if( LOCALTEST ) json.testmode = true;
	log( 'API: ' + req + (json && ('/' + JSON.stringify( json )) || '') );
	form?.append( 'json', JSON.stringify( json ) );

	let res;
	window.API_WAITING_REQUESTS++;
	try {
		let request = {
			method: 'POST',
			mode: 'cors',
			body: form || JSON.stringify( json ),
		};
		if( !form ) request.headers = {
			'Content-Type': 'application/json',
		};

		res = await fetch( `${url}${req}`, request );
	} catch( e ) {
		dropWaitingSpinners( 'api' );
		window.API_WAITING_REQUESTS--;
		return;
	}
	dropWaitingSpinners( 'api' );
	window.API_WAITING_REQUESTS--;
	if( !res?.ok ) return;
	let final;
	try {
		final = await res.json();
	} catch( e ) {
		log( `Failed fetch ${req}/ { ${JSON.stringify( json )} }` );
		return;
	}
	if( !final ) return final;

	// Onesignal setting
	if( final.onesignal_externaluserid ) {
		window.ONESIGNAL_EXTERNALUSERID = final.onesignal_externaluserid;
		fire( 'onesignal_externaluserid', final.onesignal_externaluserid );
	}

	if( final.error==='notauthorized' ) {
		// Слетела авторизация, разлогинимся
		if( !DEBUG )
			clearAuthorization( 'api' );
	} else if( final.toast )
		toast( localize( final.toast ) );
		// Закомментирован toast ошибки, потому что не всегда его надо показывать.
	// системные запросы должны отрабатываться "тихо".
	else if( !internal && final.error && (LOCALTEST || final.error[0]!=='#') )
		toast( final.error );

	if( final.bigwindow ) {
		console.log( 'Show Bigwindow reply window' );
		infoWin = makeBigWindow( {
			repeatid: 'apibigwindow',
			title: final.bigwindow.title || '{Information}',
			picture: final.bigwindow.picture,
			html: final.bigwindow.html || final.bigwindow
		} );
		// infoWin.$( '.body' ).innerHTML = final.bigwindow;
		infoWin.show();
	}

	// Обработка требования покупки
	let fants = final.fants || final.needfants && { need: final.needfants, available: final.fants_available };
	if( fants ) {
		if( fants.forclub_id ) {
			// Need to deposit money to club account
			import( './mods/team.js' ).then( mod => mod.deposit( User.setTeam( fants.forclub_id ) || User.myself, {
				fant_available: fants.available,
				title: final.payreason || options?.needfants_title,
				value: fants.need - fants.available
			} ) );
			return;
		}

		let ttl = final.payreason;
		if( ttl ) ttl += '. ';
		ttl += `{Necessary}: ${fants.need}`;
		import( './mods/shopping.js' ).then( mod => mod.shopping( {
			ids: 'fants',
			payreason: final.payreason,
			title: ttl,
			needfants: final.needfants,
			min2buy: fants.need - fants.available
		} ) );
		return;
	} else if( final.needloots ) {
		// Shopping!
		shopping( {
			ids: final.needloots,
			reason: final.payreason
		} );
		return;
	} else if( final.badloots ) {
		// Shopping!
		shopping( { ids: 'bad-' + final.badloots, reason: final.payreason } );
		return;
	} 

	if( final.actions ) {
		console.log( 'Make actions: ' + JSON.stringify( final.actions ) );
		for( let o of final.actions ) {
			if( 'location' in o ) {
				if( !o.location ) location.reload();
				else location.assign( o.location );
				return;
			}
			if( !o.selector ) continue;
			let collection = o.selector==='*' || o.selector==='mine' ? [element] : document.body.querySelectorAll( o.selector );
			for( let el of collection ) {
				if( !el ) continue;
				console.log( el );
				if( o.action==='remove' ) {
					el.remove();
					return;
				}
				if( el[o.action] ) el[o.action]();
				if( o.sethtml )
					el.html( o.sethtml );
				else if( o.content )
					el.setContent( o.content );
				if( o.setdata )
					for( let k in o.setdata ) {
						log( `dataset[${k}]=${o.setdata[k]}` );
						el.dataset[k] = o.setdata[k];
					}
			}
		}
	}

	// Обновление информации в окнах
	if( final.updatedata )
		for( let origin in final.updatedata )
			updateOrigin( origin, final.updatedata[origin] );

	return final;
}

let previewWin, previewImage;
window.previewImg = ( e, src, srcset, origin ) => {
	e.stopPropagation();
	e.preventDefault();
	if( !previewWin ) {
		previewWin = makeBigWindow( "<img class='preview display_none'>" );
		previewWin.style.background = 'rgba( 100, 100, 100, 0.8 )';
		previewImage = previewWin.$( '.preview' );
		previewImage.onload = () => previewImage.show();
	}
	// if( ) TODO: доделать превью с отложенной загрузкой
	previewImage.src = src;
	previewImage.srcset = srcset ?? '';
	if( !previewImage.complete ) previewImage.hide();
	previewWin.show();
}

if( location.search.includes( 'uploadtype' ) ) import( /* webpackChunkName: "upload" */
	/* webpackMode: "lazy" */ './mods/upload.js' );

window.shopping = ( ids, reason, p ) => {
	let params = typeof ids==='object' ? ids : {
		ids: ids,
		reason: reason,
		...p
	};
	params.ids = params.ids?.toLowerCase() || '';

	import( './mods/shopping.js' ).then( mod => mod.default( params ) );
};

window.toureditModule = () => localStorage.touredit_interface==='new'? 'touredit_tools' : 'touredit';
async function execute( e, t, params ) {
	let exec = params?.execute || t?.dataset.execute || e;
	console.log( 'Executing ' + exec );
	if( e instanceof Event ) {
		e.stopPropagation();
		e.preventDefault();
	}
	let
		ar = exec.split( '.' ),
		modname = ar.length>1 ? ar[0] : 'tools',
		func = ar.length>1 ? ar[1] || 'default' : ar[0],
		mod;
	if( modname==='golocation' ) return goLocation( ar[1] );
	if( modname==='bugreport' ) mod = await import( './mods/bugreport.js' );
	else if( modname==='tools' ) mod = await import( './mods/tools.js' );
	else if( modname==='touredit' ) mod = await import( `./mods/${toureditModule()}.js` );
	else if( modname==='toureditnew' ) mod = await import( `./mods/touredit_tools.js` );
	else if( modname==='tg' ) mod = await import( /* webpackExclude: './tg.js' */ './mods/tg.js' );
	else if( modname==='inv' ) mod = await import( /* webpackExclude: './inv.js' */ './mods/inv.js' );
	else if( modname==='usersview' ) mod = await import( './mods/usersview.js' );
	else if( modname==='team' ) mod = await import( './mods/team.js' );
	else if( modname==='chat' ) mod = await import( './mods/chat.js' );
	else if( modname==='teamadmin' ) mod = await import( './mods/teamadmin.js' );
	else if( modname==='userinfo' ) mod = await import( './mods/userinfo.js' );
	else if( modname==='auth' ) mod = await import( './auth.js' );
	else if( modname ) mod = await import( `./mods/${modname}.js` );
	// /* webpackIgnore: true */ is for real import external module
	// mod = await import( /* webpackIgnore: true */ './' + ar[0] + '.js' );
	params ||= {};
	params.target = t;
	if( t ) for( let k in t.dataset ) params[k] = t.dataset[k]

	if( mod ) {
		func ||= 'default';
		// log( 'Imported module ' + mod + ' for func ' + func );
		if( mod[func] ) mod[func]( params );
		else mod.default?.[func]( params );
	} else {
		if( func==='signout' ) signout();
	}
}

window.execute = execute;

window.clearAuthorization = reason => {
	if( isGuest() ) return;			// No drop guest authorization
	log( 'clearAuthorization ' + reason );
	delete localStorage.lastsuccessauth;
	// delete localStorage.authorization;
	delete sessionStorage.authorization;
	window.UIN = window.AUTH = null;
	window.myself = { _reason: 'CLEAR' };
	document.cookie = 'authorization=; path=/; max-age=-1; samesite=lax; secure';
}

async function signout() {
	await API( '/signout' );
	clearAuthorization( '/signout' );
	if( !window.cordova ) location.reload();
}

function toClipboard( t ) {
	if( t.dataset.toclipboard && navigator.clipboard ) {
		let data = t.dataset.toclipboard;
		if( data==='*' ) data = t.textContent;
		log( 'Toclipboard ' + data );
		navigator.clipboard.writeText( data );
		toast( '{Copied}' );
	}
}

window.inappBrowser = ( url, features ) => {
	log( 'Opening inapp: ' + url );
	window.cordova?.InAppBrowser?.open( url, '_blank', 'location=no,zoom=no' )
	// || window.open( url/*.replace( '//client.', '//www.' )*/, '_blank', 'toolbar=no,menubar=no,location=no,zoom=no,' + (features || '') );
	|| window.open( url/*.replace( '//client.', '//www.' )*/ );
}

window.inapppurchaseOnly = () => (window.cordova && (!UIN || elephCore?.auth?.info?.inapppurchase!=='no'));

/*
let winSelect;
window.selectByWindow = params => {
	cssInject( 'edit' );
	if( !winSelect )
		winSelect = makeBigWindow();
	let str = '<div class="column">';
	if( params.buttons ) {
		for( let b of params.buttons ) {
			str += `<button data-closeselect='${b.select}'>${b.title}</button>`;
		}
	}
	winSelect.html( str );
	return winSelect.promiseShow();
}
*/

window.waitingAuthResolve = null;
window.dropWaitAuth = () => waitingAuthResolve = null;
window.forceWaitAuth = () => new Promise( resolve => {
	waitingAuthResolve = resolve;
} );
window.waitAuth = type => {
	if( window.modules.Auth ) return window.modules.Auth.checkAuth( type );
	return forceWaitAuth();
}

window.checkAuth = async () => {
	if( window.modules.Auth ) return window.modules.Auth.checkAuth( 'complete' );
	// return new Promise( resolve => {
	// 	window.waitingAuthResolve = resolve
	let mod = await import( './auth.js' );
	return mod.default.checkAuth( 'complete' );
}

if( !window.LOCALTEST ) {
	window.addEventListener( 'error', function( e ) {
		if( !e.error ) return;
		let stack = e.error.stack,
			message = e.error.toString();
		if( message==='out of memory' ) {
			// Firefox goes out of memory, force reload the page
			return location.reload( true );
		}

		// Если работаем больше 3 суток, принудительно обновим вкладку
		if( !window.cordova && (Date.now() - STARTTIME>1000 * 60 * 60 * 24 * 3) )
			return location.reload( true );

		if( sessionStorage.bugreportforced ) return;
		sessionStorage.bugreportforced = true;
		if( Date.now()<localStorage.nextforcetime ) return;
		localStorage.nextforcetime = Date.now() + 60000;			// Форс ошибки не чаще раз в минуту
		if( stack ) message += '\n' + stack;
		window.bugReport( '--JS-- ' + message, e );
	} );
}

let Today, Yesterday;

function newdayarrive() {
	let now = new Date, newday = !!Today;
	Today = window.TODAY = now.toLocaleDateString();
	Yesterday = window.YESTERDAY = (new Date( Date.now() - 24 * 60 * 60 * 1000 )).toLocaleDateString();
	now.setHours( 23, 59, 59, 999 );
	setTimeout( newdayarrive, now.getTime() - Date.now() + 3000 );

	if( newday ) {
		log( 'New day arrived, refill chats' );
		for( let o of $$( '.listdaytitle[data-daystring]' ) )
			o.setContent( o.dataset.daystring===Yesterday ? '{Yesterday}' : o.dataset.daystring );
	}
}

window.showBalance = ( bal, sym, dec ) => {
	if( sym && typeof sym==='object' ) sym = sym.currency || '';
	if( bal===undefined ) return '';
	bal = Math.trunc( +bal*100 )/100;
	let prefix = sym==='?'? '💰' : '',
		postfix = ''; // sym && sym!=='?'? ' ' + sym : '';
	if( dec==='no' )
		return `${prefix}${Math.floor( bal )}${postfix}`;
	else if( dec==='pretty' )
		return `${prefix}${prettyFants(bal)}${postfix}`;
	else if( dec==='always' || bal % 1 ) {
		let decpart = bal % 1 ? bal.toString().replace( /.*\./, '.' ) : '.0';
		return `<span>${prefix}${Math.floor( bal )}</span><span style='margin-left: 0.1em; font-size: 60%'>${decpart}${postfix}</span>`;
	} else
		return `${prefix}${bal}${postfix}`;
}

newdayarrive();

window.mayReview = () => UIN==='1396791' || window.cordova && !window.UIN;

if( document.readyState==='complete' ) onLoad();
else window.addEventListener( 'load', onLoad );

/*
let resizeObserver, resizeMap = new Map;
window.observeResize = ( element, func ) => {
	if( !window.ResizeObserver ) return;
	resizeObserver ||= new ResizeObserver( entries => {
		for( let entry of entries ) {
			resizeMap.get( entry.target )?.( entry );
		}
	} );

	resizeMap.set( element, func );
	resizeObserver.observe( element );
}
*/

const transformTypes = {
	fant_balance: window.showBalance,
	balance: window.showBalance
};
window.updateOrigin = ( origin, result, element ) => {
	for( let o of (element || document.body).$$( `[data-name^="${origin}."]` ) ) {
		let f = o.dataset.name.replace( origin+'.', '' );
		if( f in result )
			updateData( o, transformTypes[f]?.(result[f]) || result[f] );
	}

	for( let o of (element || document.body).$$( `[data-origin="${origin}"][data-field]` ) ) {
		let f = o.dataset.field;
		if( f in result ) o.setContent( transformTypes[f]?.(result[f]) || result[f] );
	}
}

window.currency = curr => {
	return curr==='DEMO' && '🦔' || curr;
}

window.checkUniq = str => {
	const symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	if( str.length<11 ) return;
	let ch = 0;
	for( let i=2; i<str.length; i++ ) {
		if( !symbols.includes( str[i] ) ) return;
		ch += str.charCodeAt( i );
	}
	ch %= 62;
	if( str[1]!==symbols[ch%62] ) return;
	return str;
}

window.fixedEncodeURIComponent = str => {
	return encodeURIComponent(str).replace(
		/[!'()*]/g,
		(c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
	);
}

// Long press handling
window.dropDownMenu = async ( str, e, params ) => {
	let mod = await import( './mods/dropdown.js' );
	mod.dropDown( str, e, params );
}

(function longPressHandle() {
	document.addEventListener( 'contextmenu', contextMenu/*, true*/ );

	function contextMenu( e ) {
		if( e.target.onlongpress ) {
			// If there is a handler for long press
			let f = e.target.onlongpress;
			// log( 'Starting long press' );
			f( e );
			e.preventDefault();
			return false;
		}
		if( window.getSelection().type==='Range' ) return;
		// Ask for bugreport if isn't visible
		if( [ 'TEXTAREA', 'INPUT', 'A' ].includes( e.target.tagName ) || $( '#bugreport.visible' ) ) return;
		e.preventDefault();
		// If there is own handler, just stop system
		if( e.target.oncontextmenu ) return;
		let str = '<span data-execute="bugreport.default">🐞 {Bugreport}</span>';
		dropDownMenu( str, e );
	}
})();

window.isinAppPurchase = function() { return !window.DEBUG && !!window.CdvPurchase; }

function dropWaitingSpinners2s() {
	for( let el of $$( '.spinner' ) ) {
		let time = +el.spinnerTime;
		if( time && Date.now()-time>2000 )
			el.setSpinner( false );
	}
}

function dropWaitingSpinners( type ) {
	let spinner = '.waiting_background';
	if( type ) spinner += `[data-spinner="${spinner}"]`;
	for( let el of $$( spinner ) ) {
		el.classList.remove( 'waiting_background', 'spinner' );
		if( 'disabled' in el ) el.disabled = false;
	}
}

HTMLElement.prototype.waitForAction = function() {
	this.classList.add( 'waiting_background' );
	if( 'disabled' in this ) this.disabled = true;
}

HTMLElement.prototype.setSpinner = function( val ) {
	this.classList.toggle( 'spinner', !!val );
	if( typeof val==='string' ) this.waitForAction();
	if( val ) cssInject( 'spinner' );
	else {
		for( let el of this.$$( '.spinner' ) )
			el.classList.remove( 'spinner' );
		if( 'disabled' in this ) this.disabled = false;
	}
}

async function checkMyVersion() {
	if( !SPHEREVERSION ) return;
	let res = await fetch( `https://s.${DOMAIN}/sphere/stablefoldername.txt?${Date.now()}` ),
		stable = (await res.text())?.trim();
	window.SPHERESTABLEVERSION = stable;
	if( !stable || stable===SPHEREVERSION ) return;		// OK, latest version
	window.SPHERENEEDUPDATE = stable;
	let stabledate = new Date( stable.split( '.' )[0] ),
		mydate = new Date( SPHEREVERSION.split( '.' )[0] ),
		diff = +stabledate - (+mydate),
		diffdays = diff/1000/60/60/24;
	if( diffdays>0 )
		SPHERENEEDUPDATE = diffdays;

	if( diffdays>15 && !window.cordova ) {
		// Force update page
		if( !sessionStorage.forcedreload ) {
			sessionStorage.forcedreload = true;
			console.warn( 'Version is outdated. Reloading' );
			location.reload();
		}
	}
}

window.makeCool ||= options => {
	import( './mods/effects.js' ).then( mod => {
		mod.makeCool( options );
	})
}

window.setLoginStr = str => {
	if( window.socketLoginStr===str ) return;
	window.socketLoginStr = str;
	window.elephCore?.toserver( str || 'LOGOUT' );
}

// if( localStorage.lastsuccessauth || readCookie( 'authorization' ) || GETparams.get( 'confirmkey' ) )
// 	import( './mods/auth.js' );