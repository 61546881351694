import './support.js';
import './json5.js';

let all = new Map,
	check = new Set,
	globalId = 100,
	tagMask = new Map,

	//--- Parsing
	lastMajor,

	Subscribe = {};

export default Subscribe;

// Resend major
function redo( one, f ) {
	if( typeof one==='string' ) one = all.get( one );
	if( !one ) return;
	let isFunc = typeof f==='function';
	// if( LOCALTEST ) log( '$$$--- REDO FOR ---$$$ ' + f );
	for( let [ominor, data] of one.values ) {
		let secret, minor = ominor;
		if( typeof ominor==='object' ) {
			[minor, secret] = ominor;
		}
		// if( LOCALTEST ) log( '$$$ ' + minor );
		useRoute( f, {
			data: data,
			minor: minor,
			secret: secret,
			major: one.name
		});
	}
}

// TODO
// self.load( name )
// function to subscribe&save or just load results of previous subscription
// all minors stored will be sent as simply subscription
/*
		self.load = ( name, f ) => {
			if( all.get( name ) ) return self.add( name, f );
			storable.add( name );
			let stored = localStorage['sub_' + name];
			let values = stored && JSON.parse( stored );
		};
*/

/*
		Using "_" as first symbol of name allows not to send subscription to server
 */
Subscribe.debugSub = name => {
	if( all.get( name ) ) return;
	Subscribe.add( name );
};

Subscribe.add = ( name, handler ) => {
	// if( name[0]==='_' ) log( 'Trying subscribe ' + name );
	let one = setMajor( name );

	if( !one.clients ) {
		one.clients = new Set;
		if( !name.startsWith( 'solo_' ) ) check.add( one );
		let reg = window.elephCore?.myRegs.get( name );
		if( reg ) Subscribe.set( 'myregistration', reg );
	}

	let client = { sub: one, uniq: globalId++ };
	one.clients.add( client );
	if( one.clients.size===1 || !one.sent ) modified();

	client.functions = new Set;
	if( handler ) {
		let func = typeof handler==='function' && handler;
		client.functions.add( handler );
		client.func = func;
		// client.parser = !func && f;

		if( one.values.size )
			delay( () => redo( one, handler ) );
	}
	client.release = () => Subscribe.delete( client );
	client.redo = f => {
		if( !f._resendall ) return;
		f._resendall = null;
		redo( name, f );
	};
	client.get = key => one.values.get( key );
	client.addFunc = function( f, comment ) {
		if( this.functions.has( f ) ) return;
		this.functions.add( f );
		if( one.values.size ) {
			f._resendall = () => this.redo( f );
			f._resendall._comment = comment;
			delay( f._resendall );
		}
	};
	return client;
};

Subscribe.apiGet = async ( url, name ) => {
	let res = await API( url );
	if( !res ) return;
	for( let k in res ) {
		let key = name + '.' + k;
		log( 'Setting value for ' + key + ': ', res[k] );
		Subscribe.set( key, res[k] );
	}
};

Subscribe.delete = client => {
	let sub = client?.sub;
	if( !sub ) return;
	if( !sub.clients.has( client ) ) {
		log( 'Deletion of unrecognized subscription ' + sub.name );
		return;
	}
	sub.clients.delete( client );
	check.add( sub );
	modified();
};

Subscribe.addParser = ( major, func ) => {
	if( major[0]==='_' ) log( 'addParser ' + major );
	if( major.slice( -1 )==='*' ) {
		let mask = major.slice( 0, -1 );
		let set = tagMask.get( mask );
		if( !set ) tagMask.set( mask, set = new Set );
		set.add( func );
	} else {
		let one = setMajor( major );
		one.tags.add( func );
		redo( major, func );
	}
};

Subscribe.removeParser = ( major, func ) => {
	if( major ) {
		let one = all.get( major );
		one && one.tags.delete( func );
	} else {
		all.forEach( one => one.tags.delete( func ) );
		tagMask.forEach( t => t.delete( func ) );
	}
};

function update() {
	let plus = '', minus = '';
	for( let sub of check ) {
		if( sub.name[0]==='_' ) continue;		// Special
		if( sub.sent ) {
			if( !sub.clients.size ) {
				minus += ' ' + sub.name;
				sub.sent = false;
				// Удаляем значения, чтобы не мусорили в памяти. В первую очередь касается больших бессмысленных объектов
				// однако, если их не удалять, тоже должно работать..
				// sub.values.clear();
			}
		} else {
			if( sub.clients.size ) {
				sub.sent = true;
				plus += ' ' + sub.name;
			}
		}
	}
	let str = '';
	if( minus ) str = 'UNSUBSCRIBE' + minus + '\n';
	if( plus ) str += 'SUBSCRIBE' + plus + '\n';

	if( str ) fire( 'toserver', str );
}

Subscribe.delayRoute = ( routes, params ) => {
	delay( () => Subscribe.route( routes, params ) );
};

document.addEventListener( 'route', e => {
	Subscribe.route( e.detail.routes, e.detail.params )
} );

Subscribe.route = ( routes, params ) => {
	// let minor = params;
	if( typeof params==='string' ) {
		let [_,minor,data] = params.match( /^\s*(\S*)\s?(.*)/ );
		params = { minor: minor, data: data };
	}
	// else minor = params.minor || params[0];
	// if( params===minor ) params = {};
	for( let r of routes ) {
		// let f = typeof r==='function' && r || r[minor];
		useRoute( r, params );
		// if( LOCALTEST && f && typeof f!=='function' ) debugger;

		// f && f( params.data || params[1], minor, params.secret, params.major, params );
	}
};

function useRoute( f, params ) {
	if( f._resendall ) {
		// Этот механизм применяется, чтобы не произошло отправки позднее полученных данных
		// до обработки всего необходимого предварительного блока
		log( 'Resend all before send one line: ' + f._resendall._comment );
		f._resendall()
	}
	let minor = params.minor ?? params[0],
		data = params.data ?? params[1];
	// if( LOCALTEST ) log( '### ' + f );
	try {
		if( typeof f==='function' )
			f( data, minor, params.secret, params.major, params );
		else {
			let fk = f[minor] || f['*'];
			if( fk ) {
				if( Array.isArray( fk ) ) {
					for( let f of fk ) f( data, minor, params.secret, params.major, params );
				} else {
					if( typeof fk==='function' )
						fk( data, minor, params.secret, params.major, params );
					else
						if( LOCALTEST ) debugger;
						else bugReport( `Not a function. Minor=${minor}; ${JSON.stringify( fk ) }` );
				}
			}
		}
	} catch( e ) {
		bugReport( 'redo: ' + minor + ' ' + JSON.stringify( data ), e );
	}
}

function routeClients( params ) {
	// if( !params.minor ) return;
	let m = all.get( params.major );
	if( !m || !m.clients ) return;
	for( let client of m.clients ) {
		for( let f of client.functions )
			useRoute( f, params );
	}
}

function modified() {
	delay( update );
}

dispatch( 'connected', () => {
	// Restore all current subscriptions
	for( let [, sub] of all ) sub.sent = false;
	modified();
} );

function setMajor( major ) {
	let one = all.get( major );
	if( one ) return one;
	if( !major && DEBUG ) debugger;
	if( !major ) return;
	if( !major.includes( 'table_' ) ) log( 'Creating major ' + major );
	one = {
		name: major,
		values: new Map,
		tags: new Set,
		clients: null,
		sent: false
	};
	all.set( major, one );
	return one;
}

Subscribe.tryParse = ( data, minor, secret, major, cmd ) => {
	if( !minor && (data==='DONE' || data==='NOTFOUND') )
		minor = 'DONE';
	if( minor==='DONE' && secret ) return; // !!! Секретные подписки могут заканчиваться тихо
	let one = setMajor( major );
	if( data==='SUBSCRIBED' ) return;
	// if( minor==='chat' || minor==='kibichat' ) sendonly = true;
	let params = {
		major: major,
		minor: minor,
		secret: secret,
		data: data,
		cmd: cmd
	};

	if( (!cmd || cmd!=='SEND') && minor!=='DONE' && minor ) {
		let key = secret? [minor, secret] : ( minor || '' );
		if( cmd==='MSET' ) {
			// Another way to set data; parse and set full value
			let map = one.values.get( key );
			if( !map || !('has' in map) ) {
				map = new Map;
				one.values.set( key, map );
			}
			// parseMSET( data, map );
			map.__changed ||= map.parseMSET( data );
			params.map = map;
		} else {
			if( minor )
				one.values.set( key, data );
		}
	}

	// При снятии подписки с секретного поля, удалим его из сохранений
	if( secret && ( data==='UNSUBSCRIBED' ) ) {
		for( let [k,v] of one.values )
			if( typeof k==='object' && k[1]===secret )
				one.values.delete( k );
	}

	if( data==='DENIED' ) {
		// Считаем, что не отсылали запрос на это поле, т.к. был отказ
		one.sent = false;
	}

	// Base parsing!
	routeClients( params );

	let fn = one.tags;
	if( !fn ) {
		if( major[0]==='_' ) log( 'No parser tags' );
		return 0;
	}
	let count = 0;
	for( let f of fn ) {
		useRoute( f, params );
		count++;
	}
	return count;
};

Subscribe.set = ( path, data ) => {
	let [major, minor] = path.split( '.' );
	// if( major[0]==='_' ) log( 'SET ' + path + ' ' + JSON5.stringify( data ) );
	Subscribe.tryParse( data, minor, undefined, major );
};

Subscribe.get = path => {
	let [major, minor] = path.split( '.' );
	let one = all.get( major );
	return one?.values.get( minor ) || undefined;
};

Subscribe.drop = major => {
	all.get( major )?.values.clear();
};

if( LOCALTEST ) window.$do = str => Subscribe.parse( str );

dispatch( 'fromserver', str => {
	if( !str ) return;
	log( LOCALTEST? '<--- ' + str :
		'<--- ' + str , 'fromserver' );
	// if( str[0]==',' ) str = str.slice( 1 );
	// Для корректного парсинга JSON замена \ на // является ошибочной
	// str = str.replace( '\\', '//' );
	Subscribe.parse( str );
} );

Subscribe.unserialize5 = data => {
	let parsed;
	if( data && (data[0]==='"' ||
		(data[0]==='[' && data.slice( -1 )===']') ||
		(data[0]==='{' && data.slice( -1 )==='}' && '"{[ '.includes( data[1] ))) ) {
		try {
			parsed = JSON.parse( data );
		} catch( e ) {
			// Faulted, keep string
			try {
				parsed = JSON5.parse( data );
			} catch( err ) {
				log( 'JSON5 failed: ' + err );
				log( 'source: ' + data );
			}
		}
	}
	return parsed;
};

Subscribe.parse = str => {
	let lines = str.split( '\n' );

//        var pushtoarray = gsArray.length>0;
	let l = lines.length;
	let major, minor;
	for( let i = 0; i<l; i++ ) {
		let el = lines[i].trim();
		if( !el || el[0]===';' ) continue;
		let [path] = el.split( ' ', 1 );
		let data = el.slice( path.length + 1 ), cmd;
		if( path==='TIME' ) {
			let diff = Date.now() - (+data);
			if( !window.TIMESERVERDIFF || diff<window.TIMESERVERDIFF ) {
				window.TIMESERVERDIFF = diff;
				log( 'Time correction is ' + window.TIMESERVERDIFF );
			}
			continue;
		}
		if( path==='REPLY' ) {
			fire( 'serverresponse', data );
			continue;
		}
		if( path==='VERSION' ) {
			Subscribe.version = data;
			continue;
		}
		if( path==='AUTH' ) {
			Subscribe.auth = data;
		}

		if( path==='SET' || path==='MSET' || path==='STATUS' || path==='SEND' ) {
			cmd = path;
			[path] = data.split( ' ', 1 );
			data = data.slice( path.length + 1 );
		}
		// if( cmd==='SEND' ) cmd = 'SET';
		[major, minor] = path.split( '.' );
		if( !major ) {
			major = lastMajor;
			if( !major ) bugReport( 'Major Not Found' );
		} else {
			// if( lastMajor==major ) debugger;
			if( cmd==='SET' || cmd==='SEND' ) lastMajor = major;
		}
		if( minor==='CHAT' || minor==='chat' ) {
			// Store chat for petitions
			window.allChats += data + '\n';
		}
		let secret;
		if( major.includes( '#' ) ) {
			// Secret postfix, format major#secret.field
			[major, secret] = major.split( '#', 2 );
		}
		if( minor?.includes( '!' ) ) {
			[secret,minor] = minor.split( '!', 2 );
		}

		// if( `[{'"`.includes( data[0] ) ) data = JSON5.parse( data );
		let parsed = Subscribe.unserialize5( data );

		let count = 0, value = parsed || data;
		if( LOCALTEST )
			count = Subscribe.tryParse( value, minor, secret, major, cmd );		// Try without mask
		else try {
			count = Subscribe.tryParse( value, minor, secret, major, cmd );		// Try without mask
		} catch( e ) {
			bugReport( 'tryParse: ' + JSON.stringify( data ), e );
		}
		for( let [k, set] of tagMask ) {
			if( major.startsWith( k ) ) {
				for( let f of set ) f( parsed || data, minor, {
					secret: secret,
					major: major,
					postfix: major.replace( k, '' )
				} );
				count++;
			}
		}
	}
	// Всё отработали, если в очереди есть что-то, надо поставить таймер
	// сообщением "все обработано", которое обрабатывает проигрыватель
};

window.elephSubscribe = Subscribe;
window.neoSubscribe = Subscribe;
window.modules.Subscribe = Subscribe;

// Check first auth
modules.Auth?.firstAuth();
