"use strict";

import './support.js';

window.NEOBRIDGE = true;

window.setCoreParams(   {
	id: 'neo',
	easypay: true,
	noSwipe: true,
	domain: 'neobridge.eu',
	adminHost: 'https://secure.neobridge.eu',
	// server: 'wss://www.neobridge.eu/_connect',
	tableviewstart: '9',
	storelocation: true,
	defaultgame: 'bridge',
	defaultdeck: 'backcolor',
	notranslit: true,
	noplaybutton: true,
	promo: {
		playfriendsbridge: true,
		createclub: false,			// No club at the moment
	},
	noapp: true,
	decks: ['simplecolor', 'simplegerman', 'backcolor' ],
	roomAlias: {
		9: ''
	},
	onesignalappid: 'a491b023-ee5b-4072-89e1-95deb7bb7e9e'
} );

// Custom modules
window.promiseModule = modname => {
	if( modname==='chat' ) return import( './mods/chat.js' );
	// if( modname==='html2canvas' ) return import( '../require/html2canvas.min.js' );
	return null;
};

// All game modules start now
window.promiseGameModule = type => {
	if( type==='game' ) return import( './mods/game.js' );
	if( type==='cards' ) return import( './mods/cards.js' );
	if( type==='match' ) return import( './mods/match.js' );
	return null;
};

window.promiseGameExtend = type => {
	if( type==='pool' ) return import( './mods/pool.js' );
	if( type==='claim' ) return import( './mods/claim.js' );
	if( type==='bidbox' ) return import( './mods/bidbox.js' );
	if( type==='auction' ) return import( './mods/auction.js' );
	if( type==='handrecord' ) return import( './mods/handrecord.js' );
	// if( type==='littleinfo' ) return import( './littleinfo.js' );
};

import( './lang/en_module.js' );
// import( './socket.js' );		// Play via inet
import( './auth.js' );			// Standard authorization
import( './core.js' );
import( './manager.js' );
import( './room.js' );
// import( './reger.js' );
import( './mods/chat.js' );
import( './mods/intent.js' );



